<template>
  <v-container>
    <v-col v-if="isAdmin">
      <div :class="isMobile() ? `text-center` : `d-flex`">
        <v-icon color="secondary" :size="isMobile() ? `40` : `50`"
          >mdi-shield</v-icon
        >
        <h1 v-if="!isMobile()" class="ma-4">Guards</h1>
        <h3 v-else class="ma-4">Guards</h3>
      </div>
      <v-fade-transition>
        <v-row>
          <v-col :cols="isMobile() ? `` : `6`" class="mt-4">
            <v-text-field
              v-model="searchString"
              outlined
              label="Search Guard"
              dense
              @keyup="initSearch()"
              @input="
                loading = true;
                searchList = [];
              "
            >
            </v-text-field>
            <div :class="isMobile() ? `text-center` : ``">
              <v-btn
                @click="
                  () => {
                    this.$router.push('./LocationHistory');
                  }
                "
                text
                color="secondary"
                small
              >
                View Guards Location History
              </v-btn>
            </div>
          </v-col>
          <!-- dropdown filter -->
          <v-col cols="3" class="text-center mt-4" v-if="!isMobile()">
            <v-select
              v-model="guardStatus"
              :items="filterGuards"
              color="#FF9900"
              width="100%"
              height="40px"
              outlined
              dense
            >
            </v-select>
          </v-col>

          <!-- add guard button -->
          <v-col cols="3" class="text-center mt-4" v-if="!isMobile()">
            <v-btn
              @click="initAddGuard()"
              color="#FF9900"
              width="100%"
              height="40px"
            >
              <v-icon small>mdi-plus</v-icon>
              Add Guard
            </v-btn>
          </v-col>
        </v-row>
      </v-fade-transition>
      <div
        :style="
          isMobile()
            ? `
          max-height: 61vh;
          overflow-y: scroll;
          border-top-style: solid;
          border-bottom-style: solid;
          border-color: gray;
          border-width: 1px;
          `
            : ``
        "
        class="hide-scrollbar pa-1"
      >
        <v-data-table
          v-if="selectedGuard"
          :headers="headers"
          :items="
            searchString && searchString.length > 0 ? searchList : guardsList
          "
          class="elevation-1 mt-1 mb-5"
          item-key="_id"
          mobile-breakpoint="600"
          :loading="loading"
          loading-text="Loading... Please wait"
          single-expand
          show-expand
        >
          <template v-slot:[`item.name`]="{ item }">
            <td>
              {{ item.firstName + " " + item.lastName }}
            </td>
          </template>
          <template v-slot:[`item.mobile`]="{ item }">
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a :href="'tel:' + item.mobile" v-on="on" v-bind="attrs">
                    {{ item.mobile }}</a
                  >
                </template>
                <span>click to call mobile</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:[`item.edit`]="{ item, index }">
            <td>
              <v-icon
                color="primary"
                size="20"
                @click="sendEmailURL(item, index)"
                class="mr-3"
                >mdi-shield-link-variant-outline</v-icon
              >
              <v-icon
                color="primary"
                size="20"
                @click="selectGuard(item, index)"
                >mdi-shield-edit</v-icon
              >
            </td>
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="ma-4">
                <!-- Guard licence details -->
                <tr class="ml-4">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Driver's Licence Number: </span>
                    {{ item.licenseNumber }}
                  </h4>
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> COA #: </span>
                    {{ item.coaNumber }}
                  </h4>
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> COA Expiry: </span>
                    {{ convertDate(item.coaExp) }}
                    <span
                      class="emoji mt-1"
                      :style="
                        coaExpCheck(item.coaExp) == 'EXPIRED'
                          ? `color:red`
                          : `color:orange`
                      "
                      >{{ coaExpCheck(item.coaExp) }}</span
                    >
                  </h4>
                </tr>
                <!-- Guard licence details -->

                <!-- Guard next of kin -->
                <tr class="ml-4">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Next of kin: </span>
                    {{
                      item.nextOfKin.firstName +
                      " " +
                      item.nextOfKin.lastName +
                      " - " +
                      item.nextOfKin.mobile
                    }}
                  </h4>
                </tr>
                <!-- Guard next of kin -->
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
      <v-fab-transition>
        <v-btn
          v-show="isMobile()"
          color="secondary"
          dark
          fixed
          bottom
          right
          fab
          class="mb-12"
          @click="initAddGuard()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>

      <v-dialog
        v-model="guardDialog"
        :max-width="isMobile() ? `100vw` : `70vw`"
        persistent
        style="z-index: 999999"
      >
        <v-card min-height="70vh">
          <div class="pa-6" v-if="selectedGuard">
            <v-row>
              <v-col
                cols="12"
                :class="
                  $vuetify.breakpoint.width < 600 ? `text-center` : `text-right`
                "
              >
                <v-tooltip bottom v-if="!selectedGuard.isEnabled && !isAdd">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="deleteDialog = true"
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-delete-alert</v-icon
                    >
                  </template>
                  <span>Delete Guard</span>
                </v-tooltip>

                <!-- delete guard dialog -->
                <v-dialog v-model="deleteDialog" persistent max-width="320">
                  <v-card color="red">
                    <v-card-title class="text-h5">
                      <v-icon class="mr-2" color="yellow" large>
                        mdi-alert
                      </v-icon>
                      Delete Guard
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-text class="black--text mt-2">
                      <b
                        >This action cannot be undone (some reports will show
                        unknown Guard), are you sure you want to delete this
                        Guard?</b
                      >
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn
                        text
                        @click="deleteDialog = false"
                        :disabled="isLoading"
                      >
                        <b> Cancel </b>
                      </v-btn>
                      <v-btn
                        @click="deleteGuard"
                        class="white--text"
                        color="yellow darken-1"
                        text
                        :loading="isLoading"
                      >
                        <b> Confirm Delete </b>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-tooltip bottom v-if="selectedGuard.isClockedIn && !isAdd">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="
                        () => {
                          selectedGuard.isClockedIn = false;
                          clockOutGuardActivated = true;
                        }
                      "
                      :color="clockOutGuardActivated ? `error` : `secondary`"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >{{
                        selectedGuard.isClockedIn
                          ? `mdi-alarm-off`
                          : `mdi-alarm-check`
                      }}</v-icon
                    >
                  </template>
                  <span>{{
                    !clockOutGuardActivated
                      ? `Clock Guard Out`
                      : `Guard Is Now Set To Be Clocked Out`
                  }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="selectedGuard.isEnabled && !isAdd">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="resetDialog = true"
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-form-textbox-password</v-icon
                    >
                  </template>
                  <span>Reset Password</span>
                </v-tooltip>

                <!-- reset dialog -->
                <v-dialog v-model="resetDialog" persistent max-width="320">
                  <v-card>
                    <v-card-title class="text-h5">
                      <v-icon class="mr-2" color="info" large>
                        mdi-information
                      </v-icon>
                      Reset Password
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-text class="mt-2">
                      This guard's password will be reset and a randomised
                      password will be sent to the guard's email.
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn
                        :disabled="isLoading"
                        text
                        @click="resetDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        :loading="isLoading"
                        @click="resetPassword"
                        class="white--text"
                        color="green darken-1"
                        text
                      >
                        Reset password
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="
                        () => {
                          selectedGuard.isEnabled = !selectedGuard.isEnabled;
                          clockOutGuardActivated = true;
                          selectedGuard.isClockedIn = false;
                        }
                      "
                      :color="
                        selectedGuard.isEnabled ? `green darken-1` : `red`
                      "
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >{{
                        selectedGuard.isEnabled
                          ? `mdi-account`
                          : `mdi-account-cancel`
                      }}</v-icon
                    >
                  </template>
                  <span>{{
                    selectedGuard.isEnabled ? `Disable Guard` : `Enable Guard`
                  }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      size="32"
                      @click="initSubmitDialog()"
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-content-save</v-icon
                    >
                  </template>
                  <span>
                    {{
                      selectedGuard.id == null ? "Add Guard" : "Save Changes"
                    }}</span
                  >
                </v-tooltip>

                <!-- submit dialog -->
                <v-dialog v-model="submitDialog" persistent max-width="320">
                  <v-card>
                    <v-card-title class="text-h5">
                      <v-icon class="mr-2" color="info" large>
                        mdi-information
                      </v-icon>
                      {{
                        selectedGuard.id == null
                          ? `Confirm Submission`
                          : `Confirm Changes`
                      }}
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-card-text class="mt-2">
                      Verify that all of the information you entered is accurate
                      and correct prior to submitting
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn
                        :disabled="isLoading"
                        text
                        @click="submitDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <div v-if="selectedGuard.id == null">
                        <v-btn
                          :loading="isLoading"
                          @click="
                            () => {
                              selectedGuard.id == null
                                ? addGuard('PWA')
                                : updateGuard();
                            }
                          "
                          class="white--text"
                          color="green darken-1"
                          text
                        >
                          {{
                            selectedGuard.id == null
                              ? `Confirm With PWA`
                              : `Save Changes`
                          }}
                        </v-btn>
                        <v-btn
                          :loading="isLoading"
                          @click="
                            () => {
                              selectedGuard.id == null
                                ? addGuard('APP')
                                : updateGuard();
                            }
                          "
                          class="white--text"
                          color="green darken-1"
                          text
                        >
                          {{
                            selectedGuard.id == null
                              ? `Confirm With App`
                              : `Save Changes`
                          }}
                        </v-btn>
                      </div>
                      <div v-else>
                        <v-btn
                          :loading="isLoading"
                          @click="
                            () => {
                              selectedGuard.id == null
                                ? addGuard()
                                : updateGuard();
                            }
                          "
                          class="white--text"
                          color="green darken-1"
                          text
                        >
                          {{
                            selectedGuard.id == null
                              ? `Confirm Submission`
                              : `Save Changes`
                          }}
                        </v-btn>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      large
                      @click="guardDialog = false"
                      color="secondary"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-close</v-icon
                    >
                  </template>
                  <span>Exit</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="12"
                :class="$vuetify.breakpoint.width < 600 ? `text-center` : ``"
              >
                <h3>
                  {{ isAdd ? `Add Guard` : `Change Guard Details` }}
                </h3>
              </v-col>
            </v-row>
            <v-form ref="form" lazy-validation>
              <v-row class="mt-4">
                <v-col cols="12" md="6" sm="6" xs="12">
                  <v-text-field
                    v-model="selectedGuard.firstName"
                    label="First Name"
                    outlined
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" xs="12">
                  <v-text-field
                    v-model="selectedGuard.lastName"
                    label="Last Name"
                    outlined
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" xs="12">
                  <v-text-field
                    v-model="selectedGuard.email"
                    label="Email"
                    outlined
                    :rules="[rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" xs="12">
                  <v-text-field
                    v-model="selectedGuard.mobile"
                    label="Mobile"
                    outlined
                    :rules="[rules.required, rules.mobile]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" xs="12">
                  <v-text-field
                    v-model="selectedGuard.licenseNumber"
                    label="License Number"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" sm="6" xs="12">
                  <v-text-field
                    v-model="selectedGuard.coaNumber"
                    label="COA Number"
                    outlined
                  >
                    <template slot="append">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            color="primary"
                            @click="datePicker = true"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-calendar
                          </v-icon>
                        </template>
                        <span>COA Expiry Date</span>
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  <div style="margin-top: -30px; color: primary" class="ml-2">
                    <h5>COA Expiry: {{ convertDate(selectedGuard.coaExp) }}</h5>
                  </div>
                  <v-dialog persistent v-model="datePicker" max-width="300">
                    <v-date-picker
                      v-model="selectedGuard.coaExp"
                      elevation="15"
                      @input="datePicker = false"
                    ></v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h5>Next of kin</h5>
                </v-col>
                <v-col cols="12" md="4" sm="4" xs="12">
                  <v-text-field
                    v-model="selectedGuard.nextOfKin.firstName"
                    label="First Name"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="4" xs="12">
                  <v-text-field
                    v-model="selectedGuard.nextOfKin.lastName"
                    label="Last Name"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" sm="4" xs="12">
                  <v-text-field
                    v-model="selectedGuard.nextOfKin.mobile"
                    label="Mobile"
                    outlined
                    :rules="[rules.mobile]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-model="selectedGuard.notes"
                    outlined
                    label="Guard Notes"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
      </v-dialog>
    </v-col>
    <!-- notification  -->
    <v-snackbar
      v-model="notify"
      class="pt-16"
      top
      right
      :timeout="2500"
      :color="color"
    >
      {{ notificationText }}
    </v-snackbar>
    <v-dialog v-model="sendEmailURLDialog" max-width="320">
      <v-card class="text-center pb-5">
        <v-card-title class="text-h5"> SEND INSTRUCTIONS </v-card-title>
        <v-spacer></v-spacer>
        <br />
        <v-btn
          :loading="isLoading"
          @click="sendInstrucitons('PWA')"
          class="white--text"
          color="green darken-1"
        >
          Send PWA Instructions
        </v-btn>
        <br /><br />
        <v-btn
          :loading="isLoading"
          @click="sendInstrucitons('APP')"
          class="white--text"
          color="green darken-1"
        >
          Send APP Instructions
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  enabledGuards,
  disabledGuards,
  addGuard,
  updateGuard,
  deleteGuard,
  guards,
} from "../graphql/guard";
import moment from "moment";
import axios from "axios";
import { listClockingByDate } from "../graphql/clocking";
import { clockOut } from "../graphql/clocking";
import { updateGuardLocation } from "../graphql/guard";

export default {
  components: {
    //
  },
  async mounted() {
    this.$store.commit("resetSelectedGuard");
    this.showEnabledGuards();
  },

  data() {
    return {
      sendEmailURLDialog: false,
      clockOutGuardActivated: false,
      selectedIndex: null,
      zIndex: null,
      color: "",
      notificationText: "",
      notify: false,
      resetDialog: false,
      deleteDialog: false,
      isLoading: false,
      submitDialog: false,
      loading: false,
      searchTimer: null,
      searchList: [],
      searchString: null,
      datePicker: false,
      isAdd: false,
      guardDialog: false,
      guardsList: [],
      guardStatus: "Active",
      filterGuards: ["Active", "Inactive"],
      headers: [
        {
          text: "Guard name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Mobile", sortable: false, value: "mobile" },
        { text: "Email", sortable: false, value: "email" },
        { text: "Edit", sortable: false, value: "edit" },
      ],

      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        mobile: (v) => {
          if (v)
            return (
              (v.match(/[0-9 ]+/) && v.length >= 8 && v.length <= 13) ||
              "Must enter only digits and Must be 8-13 digits long"
            );
          else return true;
        },
      },
    };
  },

  // apollo: {
  //   guardsList: guards,
  // },

  computed: {
    isAdmin: {
      get() {
        return this.$store.getters["isAdmin"];
      },
    },
    selectedGuard: {
      get() {
        return this.$store.getters["selectedGuard"];
      },
    },
  },

  watch: {
    guardDialog: function (val) {
      if (!val) {
        this.$store.commit("resetSelectedGuard");
      }
    },
    guardStatus: function () {
      if (this.guardStatus === "Active") {
        this.searchString = "";
        this.showEnabledGuards();
      } else if (this.guardStatus === "Inactive") {
        this.searchString = "";
        this.showDisabledGuards();
      } else return this.showEnabledGuards();
    },
  },

  methods: {
    async initSubmitDialog() {
      let emailExist = await this.checkIfGuardExist(this.selectedGuard.email);

      if (this.isAdd == true) {
        if (emailExist) {
          alert(
            "The email already exist in the database. Please use another email."
          );
          return;
        }
      }

      this.submitDialog = true;
    },
    sendEmailURL(guard, i) {
      this.selectedIndex = i;
      this.isAdd = false;
      this.$store.commit("setSelectedGuard", guard);
      this.sendEmailURLDialog = true;
    },
    showEnabledGuards() {
      this.$apollo.addSmartQuery("guardsList", enabledGuards);
    },

    showDisabledGuards() {
      this.$apollo.addSmartQuery("guardsList", disabledGuards);
    },
    coaExpCheck(date) {
      if (date) {
        const today = moment(Date.now()).format("YYYY-MM-DD");
        let isExpired = moment(date).isBefore(today);

        if (isExpired) {
          return "EXPIRED";
        } else {
          let a = moment(today);
          let b = moment(date);
          let diff = b.diff(a, "days");
          return "EXPIRES IN: " + diff + " days";
        }
      } else {
        return "NO DATE PROVIDED";
      }
    },
    initSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search();
      }, 300);
    },
    convertDate(d) {
      return moment(d).format("DD/MM/yyyy");
    },
    async resetPassword() {
      this.isLoading = true;
      let res = await axios.patch(
        `https://apigw.guardex.co.nz/admin/users/auth0|${this.selectedGuard.id}/update`,
        { forgotPassword: true, email: this.selectedGuard.email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("apollo-token"),
          },
        }
      );
      (this.resetDialog = false),
        (this.guardDialog = false),
        (this.isLoading = false);
    },

    search() {
      if (this.searchString && this.searchString.length > 0) {
        let newList = this.guardsList.filter(
          (item) =>
            item.firstName
              .toLowerCase()
              .includes(this.searchString.toLowerCase()) ||
            item.lastName
              .toLowerCase()
              .includes(this.searchString.toLowerCase())
        );
        this.searchList = newList;
      }

      this.loading = false;
    },
    isMobile() {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },
    openDatePicker() {
      this.datePicker = true;
    },
    async initAddGuard() {
      this.isAdd = true;
      this.$store.commit("resetSelectedGuard");
      this.guardDialog = true;
    },
    async selectGuard(guard, i) {
      this.selectedIndex = i;
      this.isAdd = false;
      await this.$store.commit("setSelectedGuard", guard);
      this.guardDialog = true;
    },
    async checkIfGuardExist(email) {
      //TODO: Check if guard email already exist.
      let allGuards = await this.$apollo.query({
        query: guards.query,
        fetchPolicy: "no-cache",
      });

      const guardList = allGuards.data.guards;

      let exist = await guardList.filter((guard) => guard.email == email);

      if (exist.length > 0) {
        return true;
      }

      return false;
    },
    async addGuard(mode) {
      this.isLoading = true;
      let valid = this.$refs.form.validate();

      let newGuardDetails = null;
      if (this.isAdd && valid) {
        await this.$apollo.mutate({
          mutation: addGuard,
          variables: {
            ...this.selectedGuard,
          },
          update: (store, { data: { addGuard } }) => {
            newGuardDetails = addGuard;
            this.guardsList.push(addGuard);
          },
        });

        newGuardDetails.mode = mode;
        this.guardDialog = false;
        this.isLoading = false;
        (this.notificationText = "Added Successfully"),
          (this.color = "green darken-1"),
          (this.notify = true);
      } else {
        this.isLoading = false;
        this.submitDialog = false;
      }
      await axios.post(
        "https://apigw.guardex.co.nz/admin/users/new",
        newGuardDetails,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("apollo-token"),
          },
        }
      );
    },

    async sendInstrucitons(mode) {
      this.isLoading = true;
      this.selectedGuard.mode = mode;
      this.selectedGuard.instructionsOnly = true;
      this.selectedGuard["_id"] = this.selectedGuard.id;

      await axios.post(
        "https://apigw.guardex.co.nz/admin/users/new",
        this.selectedGuard,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("apollo-token"),
          },
        }
      );

      this.$store.commit("resetSelectedGuard");

      this.isLoading = false;
      this.sendEmailURLDialog = false;
    },

    async updateGuard() {
      this.isLoading = true;
      let valid = this.$refs.form.validate();

      if (valid) {
        if (this.clockOutGuardActivated == true) {
          try {
            await this.clockOutGuard();
          } catch (error) {
            console.warn("FROM CLOCKOUT GUARD: ", error);
            this.guardDialog = false;
            this.isLoading = false;
          }
        }

        try {
          await this.$apollo.mutate({
            mutation: updateGuard,
            variables: {
              ...this.selectedGuard,
            },
          });
        } catch (error) {
          console.warn("FROM UDPATE GUARD: ", error);
          this.guardDialog = false;
          this.isLoading = false;
        }

        /* For testing */
        /*  let data = {
          id: this.selectedGuard["id"],
          isBlocked: !this.selectedGuard.isEnabled,
          Authorization: "Bearer " + localStorage.getItem("apollo-token"),
        };

        console.log("passed data: ", data); */

        try {
          await axios.patch(
            "https://apigw.guardex.co.nz/admin/user/activation",
            {
              id: this.selectedGuard["id"],
              isBlocked: !this.selectedGuard.isEnabled,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("apollo-token"),
              },
            }
          );
        } catch (error) {
          console.warn("FROM PATCH: ", error);
          this.guardDialog = false;
          this.isLoading = false;
        }

        if (
          (this.guardStatus == "Active" &&
            this.selectedGuard.isEnabled == false) ||
          (this.guardStatus == "Inactive" &&
            this.selectedGuard.isEnabled == true)
        ) {
          this.guardsList.splice(this.selectedIndex, 1);
          this.selectedIndex = null;
        }

        this.isLoading = false;
        this.guardDialog = false;
        (this.notificationText = "Updated Successfully"),
          (this.color = "green darken-1"),
          (this.notify = true);
      }

      this.clockOutGuardActivated = false;
    },

    async clockOutGuard() {
      //get clocking
      let guardId = this.selectedGuard.id;

      let clockDate = moment().format("YYYY-MM-DD");

      let res = await this.$apollo.query({
        query: listClockingByDate,
        variables: {
          guardId: guardId,
          clockDate: clockDate,
        },
        fetchPolicy: "no-cache",
      });

      console.log("sussy rezzy: ", res);

      if (res.data.clockInList.length == 0) {
        return;
      }

      let guardClocking = res.data.clockInList[0];
      guardClocking.id = guardClocking["_id"];
      delete guardClocking["__typename"];
      delete guardClocking["_id"];
      delete guardClocking["clockIn"]["__typename"];
      delete guardClocking["clockOut"]["__typename"];

      guardClocking.clockOut.timestamp = Date.now().toString();
      guardClocking.clockOut.lat = guardClocking.clockIn.lat;
      guardClocking.clockOut.lng = guardClocking.clockIn.lng;

      // update clocking with clockout
      await this.$apollo.mutate({
        mutation: clockOut,
        variables: guardClocking,
      });

      let variables = {
        id: guardId,
        isClockedIn: false,
      };

      await this.$apollo.mutate({
        mutation: updateGuardLocation,
        variables: variables,
      });

      //update guardobj isClockedIn false
    },

    async deleteGuard() {
      this.isLoading = true;
      await axios.delete(
        `https://apigw.guardex.co.nz/admin/users/auth0|${this.selectedGuard.id}/delete`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("apollo-token"),
          },
        }
      );
      let res = await this.$apollo.mutate({
        mutation: deleteGuard,
        variables: {
          id: this.selectedGuard.id,
        },
      });
      // location.reload();
      this.guardsList = await this.guardsList.filter(
        (obj) => obj.id != res.data.deleteGuard["_id"]
      );
      this.guardsList = await this.guardsList.filter(
        (obj) => obj["_id"] != res.data.deleteGuard["_id"]
      );
      this.isLoading = false;
      this.guardDialog = false;
    },
  },
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

a {
  text-decoration: none !important;
}
</style>
