<template>
  <v-container v-if="selectedAlarm">
    <v-form ref="form" lazy-validation>
      <v-container>
        <v-row>
          <v-col class="mt-4" lg="6" md="6" sm="6" xs="6">
            <v-btn @click="back()" color="primary">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <!-- add attachments -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  @click="initAttachmentDialog()"
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  class="ml-2"
                >
                  <v-icon> mdi-paperclip </v-icon>
                </v-btn>
              </template>
              <span> Attachments </span>
            </v-tooltip>

            <!-- attachments dialog -->
            <v-dialog
              v-if="selectedAlarm"
              v-model="addAttachmentsDialog"
              max-width="1200"
              style="overflow: auto"
            >
              <v-card class="overflow-y-auto" height="85vh">
                <v-card-title>
                  Alarm Report Attachments
                  <v-spacer></v-spacer>
                  <v-card-actions
                    class="d-flex flex-row-reverse justify-space-between mb-6"
                    :style="isMobile() ? `width: 100%` : ``"
                  >
                    <v-btn
                      @click="addAttachmentsDialog = false"
                      color="primary"
                      icon
                      x-large
                    >
                      <v-icon> mdi-check </v-icon>
                    </v-btn>
                    <v-btn
                      @click="addAttachmentsDialog = false"
                      color="secondary"
                      icon
                      x-large
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card-title>

                <!-- add image button -->
                <v-card-actions>
                  <v-row justify="center">
                    <v-btn @click="addPhoto" color="secondary" elevation="4">
                      Add Image
                      <input
                        class="d-none"
                        ref="uploader"
                        type="file"
                        accept="image/*"
                        @change="onFileChanged"
                      />
                    </v-btn>
                  </v-row>
                </v-card-actions>

                <!-- display images -->
                <v-container>
                  <v-row class="pa-4">
                    <v-col
                      v-for="(items, index) in attachmentsList"
                      :key="index"
                      cols="12"
                      lg="4"
                      md="4"
                      sm="6"
                      xs="12"
                    >
                      <v-card
                        class="mb-4 text-right"
                        style="position: relative"
                        elevation="4"
                        outlined
                        min-width="125"
                        height="280"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              style="
                                z-index: 1000;
                                position: absolute;
                                margin-left: -60px;
                                margin-top: 5px;
                              "
                              v-bind="attrs"
                              v-on="on"
                              @click="removePhoto(index)"
                              small
                              color="rgb(0, 0, 0, 0.2)"
                            >
                              <v-icon color="white">mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span> Remove Attachment </span>
                        </v-tooltip>

                        <a :href="items" target="_blank">
                          <v-img :src="items" height="100%" />
                        </a>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col class="text-right mt-4" cols="6" lg="6" md="6" sm="6" xs="6">
            <v-dialog v-model="confirmDeleteDialog" persistent max-width="320">
              <template #activator="{ on, attrs }">
                <v-icon
                  size="30"
                  :color="selectedAlarm.emailSent ? `green` : `gray`"
                  v-if="isAdmin && selectedAlarm.id"
                  class="mr-2 mt-2"
                  @click="sendEmail()"
                  >{{
                    selectedAlarm.emailSent
                      ? `mdi-email-check`
                      : `mdi-email-alert`
                  }}</v-icon
                >
                <v-btn
                  v-if="selectedAlarm.id !== null"
                  class="white--text"
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  v-if="isAdmin && selectedAlarm.id"
                  class="white--text mr-2"
                  color="primary"
                  @click="printPDF()"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <v-card color="red">
                <v-card-title class="text-h5">
                  <v-icon class="mr-2" color="yellow" large> mdi-alert </v-icon>
                  Delete Alarm Report
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-text class="black--text mt-2">
                  <b
                    >This action cannot be undone, are you sure you want to
                    delete this alarm report?</b
                  >
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    :disabled="isLoading"
                    text
                    @click="confirmDeleteDialog = false"
                  >
                    <b> Cancel </b>
                  </v-btn>
                  <v-btn
                    @click="deleteAlarmResponse()"
                    class="white--text"
                    color="yellow darken-1"
                    text
                    :loading="isLoading"
                  >
                    <b> Confirm Delete </b>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- submit dialog -->
            <v-dialog v-model="submitDialog" persistent max-width="320">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  class="white--text ml-2"
                >
                  <v-icon>mdi-content-save</v-icon>
                  <!-- {{ selectedAlarm.id == null ? `Submit` : `Save Changes` }} -->
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  <v-icon class="mr-2" color="info" large>
                    mdi-information
                  </v-icon>
                  {{
                    selectedAlarm.id == null
                      ? `Confirm Submission`
                      : `Confirm Changes`
                  }}
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-text class="mt-2">
                  <!-- Verify that all of the information you entered is accurate and correct prior to submitting -->
                  Check all site details are correct prior to submitting
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    text
                    @click="submitDialog = false"
                    :disabled="isLoading"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    @click="
                      () => {
                        selectedAlarm.id == null
                          ? addAlarmResponse()
                          : updateAlarmResponse();
                      }
                    "
                    class="white--text"
                    color="green darken-1"
                    text
                    :loading="isLoading"
                  >
                    {{
                      selectedAlarm.id == null
                        ? `Confirm Submission`
                        : `Confirm Changes`
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>

      <!-- Alarm Details -->
      <v-container v-if="selectedAlarm">
        <span>Alarm Details</span>
        <v-card class="mb-6 pa-4" elevation="4">
          <div>
            <v-row>
              <v-col class="mt-4" cols="12" lg="6" md="6" sm="6" xs="12">
                <!-- date issued -->
                <!-- select site location -->
                <v-autocomplete
                  v-model="selectedAlarm.siteLocation"
                  item-value="siteLocation"
                  :items="enabledSites"
                  item-text="siteName"
                  label="Site Location"
                  hide-no-data
                  cache-items
                  outlined
                  :rules="rules"
                  return-object
                >
                </v-autocomplete>
                <!-- guard on duty -->
                <v-select
                  v-model="selectedAlarm.guardDetails"
                  :item-text="(item) => item.firstName + ` ` + item.lastName"
                  :items="enabledGuards"
                  label="Guard On Duty"
                  return-object
                  outlined
                  :rules="rules"
                  :readonly="!this.isAdmin"
                />

                <!-- date issued dialog & alarm activation date field -->
                <v-dialog
                  ref="dateIssuedDialog"
                  v-model="dateIssuedDialog"
                  :return-value.sync="selectedAlarm.dateIssued"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="displayDate"
                      label="Alarm Activation Date"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      readonly
                      :rules="rules"
                    />
                  </template>
                  <v-date-picker v-model="selectedAlarm.dateIssued" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dateIssuedDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dateIssuedDialog.save(selectedAlarm.dateIssued)
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

                <!-- alarm notification time -->
                <v-text-field
                  v-model="selectedAlarm.alarmNotificationTime"
                  label="Alarm Activation Time"
                  @keypress="isNumber($event)"
                  :maxlength="max"
                  outlined
                  append-icon="mdi-clock-time-three-outline"
                  :rules="rules"
                  readonly
                  @click="initTimePicker('alarm')"
                />

                <!-- site arrival time -->
                <v-text-field
                  v-model="selectedAlarm.siteArrivalTime"
                  label="Site Arrival Time"
                  @keypress="isNumber($event)"
                  :maxlength="max"
                  outlined
                  append-icon="mdi-clock-outline"
                  :rules="rules"
                  readonly
                  @click="initTimePicker('arrival')"
                />
                <!-- current geolocation -->
                <!-- <v-text-field
                  v-if="!isAdmin"
                  v-model="selectedAlarm.currentGeoLocation"
                  label="Current Geolocation"
                  outlined
                /> -->
              </v-col>
              <v-col class="mt-4" cols="12" lg="6" md="6" sm="6" xs="12">
                <!-- incident description -->
                <v-textarea
                  v-model="selectedAlarm.alarmIncidentDescription"
                  label="Alarm Incident Description"
                  outlined
                  no-resize
                  :height="!isMobile() ? `400` : `144`"
                />
              </v-col>
            </v-row>
          </div>
        </v-card>

        <!-- Police Required -->
        <span
          >Police Required
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col md="2">
                <v-switch
                  v-model="selectedAlarm.policeRequired"
                  @change="officerNeeded()"
                  :label="`
             ${selectedAlarm.policeRequired ? `Yes` : `No`}
            `"
                  color="#EA690B"
                  inset
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-container>
        </span>
        <v-card
          v-if="selectedAlarm.policeRequired"
          class="mb-6 pa-4"
          elevation="4"
        >
          <v-row>
            <v-col class="mt-4" cols="12" lg="12" md="12" sm="6" xs="12">
              <div
                v-for="(officerDetails, i) in selectedAlarm.policeInvolved"
                :key="i"
              >
                <p>Officer Involved</p>
                <v-text-field
                  v-model="officerDetails.officerName"
                  label="Officer Name"
                  :rules="policeDetailsRule"
                  outlined
                />
                <v-text-field
                  v-model="officerDetails.officerStation"
                  label="Officer Station"
                  outlined
                />
                <v-text-field
                  v-model="officerDetails.policeEventNumber"
                  label="Police Event Number"
                  :rules="policeDetailsRule"
                  outlined
                />
                <v-textarea
                  v-model="officerDetails.notes"
                  label="Notes"
                  height="144"
                  no-resize
                  outlined
                />

                <v-row class="pa-2 pb-4 d-flex flex-row-reverse">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        @click="confirmRemoveOfficerDialog(i)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        small
                      >
                        <v-icon color="red"> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span> Remove {{ officerDetails.officerName }} </span>
                  </v-tooltip>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <!-- delete officer dialog -->
          <v-dialog
            color="red"
            v-model="removeOfficerDialog"
            persistent
            max-width="300"
          >
            <v-card>
              <v-card-title class="text-h5"> Remove Officer </v-card-title>
              <v-spacer></v-spacer>
              <v-card-text class="mt-2">
                Are you sure you want to remove this officer?
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  text
                  @click="removeOfficerDialog = false"
                  :disabled="isLoading"
                >
                  Cancel
                </v-btn>
                <v-btn
                  @click="removeOfficer()"
                  class="white--text"
                  color="red darken-1"
                  text
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- add officer -->
          <v-card-actions>
            <v-row justify="center">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="addOfficer()"
                    class="white--text"
                    color="#EA690B"
                    v-on="on"
                    v-bind="attrs"
                    small
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span> Add More </span>
              </v-tooltip>
            </v-row>
          </v-card-actions>
        </v-card>

        <!-- Anyone injured -->
        <span
          >Anyone injured
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col md="2">
                <v-switch
                  v-model="selectedAlarm.anyoneInjured"
                  color="#EA690B"
                  inset
                  @change="injuredSwitch()"
                  :label="`${selectedAlarm.anyoneInjured ? `Yes` : `No`}`"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-container>
        </span>
        <v-card
          v-if="selectedAlarm.anyoneInjured"
          class="mb-6 pa-4"
          elevation="4"
        >
          <v-row>
            <v-col class="mt-4" cols="12" lg="12" md="12" sm="6" xs="12">
              <div
                v-for="(injuredPersonDetails, n) in selectedAlarm.peopleInjured"
                :key="n"
              >
                <p>Injured People</p>

                <v-text-field
                  v-model="injuredPersonDetails.firstName"
                  label="First Name"
                  :rules="injuredPersonDetailRules"
                  outlined
                />
                <v-text-field
                  v-model="injuredPersonDetails.lastName"
                  label="Last Name"
                  outlined
                />
                <v-textarea
                  v-model="injuredPersonDetails.injuryDescription"
                  label="Injury Description"
                  :rules="injuredPersonDetailRules"
                  outlined
                  no-resize
                  height="144"
                />
                <v-textarea
                  v-model="injuredPersonDetails.notes"
                  label="Extra Notes"
                  outlined
                  no-resize
                  height="144"
                />
                <v-row class="pa-2 pb-4 d-flex flex-row-reverse">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        @click="confirmRemoveInjuredDialog(n)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        small
                      >
                        <v-icon color="red"> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Remove
                      {{
                        injuredPersonDetails.firstName +
                        " " +
                        injuredPersonDetails.lastName
                      }}
                    </span>
                  </v-tooltip>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <!-- delete injured person dialog -->
          <v-dialog
            color="red"
            v-model="removeInjuredPersonDialog"
            persistent
            max-width="300"
          >
            <v-card>
              <v-card-title class="text-h5">
                Remove Injured Person
              </v-card-title>
              <v-spacer></v-spacer>
              <v-card-text class="mt-2">
                Are you sure you want to remove this injured person?
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn text @click="removeInjuredPersonDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  @click="removeInjuredPerson()"
                  class="white--text"
                  color="red darken-1"
                  text
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card-actions>
            <v-row justify="center">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="addInjuredPerson()"
                    class="white--text"
                    color="#EA690B"
                    v-on="on"
                    v-bind="attrs"
                    small
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span> Add More </span>
              </v-tooltip>
            </v-row>
          </v-card-actions>
        </v-card>

        <!-- Damaged properties -->
        <v-card class="mb-6 pa-4" elevation="4">
          <p>Damaged Properties</p>

          <div
            v-for="(propertyDetails, i) in selectedAlarm.damagedProperties"
            :key="i"
          >
            <v-col class="mt-4" cols="12" lg="12" md="12" sm="6" xs="12">
              <v-textarea
                v-model="propertyDetails.propertyName"
                label="Damage Description"
                outlined
                :rules="injuredPersonDetailRules"
              />
              <!-- <v-text-field
                v-model="propertyDetails.propertyAddress"
                label="Property Address"
                outlined
                :rules="injuredPersonDetailRules"
              /> -->
            </v-col>
            <v-row class="pa-2 pb-4 d-flex flex-row-reverse">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="confirmRemoveProperty(i)"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    small
                  >
                    <v-icon color="red"> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span> Remove {{ propertyDetails.propertyName }} </span>
              </v-tooltip>
            </v-row>
          </div>

          <!-- delete property dialog -->
          <v-dialog
            color="red"
            v-model="removePropertyDialog"
            persistent
            max-width="300"
          >
            <v-card>
              <v-card-title class="text-h5"> Remove Property </v-card-title>
              <v-spacer></v-spacer>
              <v-card-text class="black--text mt-2">
                Are you sure you want to remove this property?
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn text @click="removePropertyDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  @click="removeProperty()"
                  class="white--text"
                  color="red darken-1"
                  text
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card-actions>
            <v-row justify="center">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="addProperty()"
                    class="white--text"
                    color="#EA690B"
                    v-on="on"
                    v-bind="attrs"
                    small
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span> Add More </span>
              </v-tooltip>
            </v-row>
          </v-card-actions>
        </v-card>

        <!--hasMonitoringCompanyContacted -->
        <span
          >Has Monitoring Company Been Contacted
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col md="2">
                <v-switch
                  v-model="selectedAlarm.hasMonitoringCompanyContacted"
                  @change="contactMonitorCompany()"
                  :label="`
             ${selectedAlarm.hasMonitoringCompanyContacted ? `Yes` : `No`}
            `"
                  color="#EA690B"
                  inset
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-container>
        </span>

        <v-card
          v-if="selectedAlarm.hasMonitoringCompanyContacted"
          class="mb-6 pa-4"
          elevation="4"
        >
          <p>Monitoring Company</p>

          <v-autocomplete
            v-model="
              selectedAlarm.monitoringCompanyCombo.monitoringCompanyDetails
            "
            :disabled="enabledMonitoringCompanyList.length == 0"
            item-text="companyName"
            :items="enabledMonitoringCompanyList"
            label="Monitoring Company Name"
            cache-items
            hide-no-data
            return-object
            outlined
            :rules="rules"
          />

          <v-text-field
            v-if="selectedAlarm.monitoringCompanyCombo.monitoringCompanyDetails"
            v-model="
              selectedAlarm.monitoringCompanyCombo.monitoringCompanyDetails
                .address
            "
            readonly
            label="Company Address (readOnly)"
            outlined
          />

          <div>
            <p>Person Spoken To</p>
            <v-text-field
              v-model="
                selectedAlarm.monitoringCompanyCombo.personSpokenTo.firstName
              "
              label="First Name"
              outlined
              :rules="rules"
            />
            <v-text-field
              v-model="
                selectedAlarm.monitoringCompanyCombo.personSpokenTo.lastName
              "
              label="Last Name"
              outlined
            />
            <v-text-field
              v-model="
                selectedAlarm.monitoringCompanyCombo.personSpokenTo.telephone
              "
              label="Site Dispatch Code"
              outlined
              :rules="rules"
            />
          </div>
        </v-card>

        <span>
          Alarm Reactivated
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col md="2">
                <v-switch
                  v-model="selectedAlarm.hasAlarmReactivated"
                  @change="alarmReactivated()"
                  :label="`
             ${selectedAlarm.hasAlarmReactivated ? `Yes` : `No`}
            `"
                  color="#EA690B"
                  inset
                />
              </v-col>
            </v-row>
          </v-container>
        </span>

        <!-- Additional Details -->
        <span>Additional Details </span>
        <v-row>
          <v-col class="mt-4" cols="12" lg="12" md="12" sm="12" xs="12">
            <v-textarea
              v-model="selectedAlarm.additionalDetails"
              outlined
              no-resize
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <!-- notification  -->
    <v-snackbar
      v-model="notify"
      class="pt-16"
      top
      right
      :timeout="2500"
      :color="color"
    >
      {{ notificationText }}
    </v-snackbar>

    <!----------- TIME PICKER ----------------------->
    <v-dialog v-model="timePickerDialog" max-width="290" persistent>
      <v-card min-height="24vh">
        <v-container>
          <v-row>
            <v-col cols="6" lg="6" md="6" sm="6">
              <v-btn @click="timePickerDialog = false" icon>
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              Time
            </v-col>
            <v-col cols="6" lg="6" md="6" sm="6">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div class="d-flex justify-end">
                    <v-btn
                      @click="setSelectedTime()"
                      class=""
                      v-on="on"
                      v-bind="attrs"
                      icon
                    >
                      <v-icon color="secondary"> mdi-check-circle </v-icon>
                    </v-btn>
                  </div>
                </template>
                Save
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row class="pt-8" justify="center">
            <v-col cols="4" lg="4" md="4" sm="4">
              <v-autocomplete
                v-model="selectedHour"
                :items="hourListComputed"
                append-icon=""
                outlined
              />
            </v-col>
            <p style="font-size: 51px">:</p>
            <v-col cols="4" lg="4" md="4" sm="4">
              <v-autocomplete
                v-model="selectedMinutes"
                :items="minutesListComputed"
                append-icon=""
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  alarmResponseList,
  addAlarmResponse,
  deleteAlarmResponse,
  updateAlarmResponse,
  updateAlarmClean,
} from "../graphql/alarmReports";
import { sites, enabledSites } from "../graphql/sites";
import { enabledGuards } from "../graphql/guard";
import { clients } from "../graphql/clients";
import { enabledMonitoringCompanyList } from "../graphql/monitoringCompany";
import moment from "moment";
import axios from "axios";

export default {
  methods: {
    async printPDF() {
      await this.updateAlarmClean();
      let result = await axios.post(
        "https://apigw.guardex.co.nz/reports/print",
        "alarm#" + this.selectedAlarm["id"],
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("apollo-token"),
          },
        }
      );

      const blob = await this.b64toBlob(result.data, "application/pdf");
      const blobUrl = URL.createObjectURL(blob);

      window.open(blobUrl);
    },

    async b64toBlob(b64, contentType, sliceSize = 522) {
      const byteCharacters = window.atob(b64);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });

      console.log(blob);
      return blob;
    },
    initTimePicker(type) {
      if (type == "alarm" && this.selectedAlarm.alarmNotificationTime) {
        this.selectedHour =
          this.selectedAlarm.alarmNotificationTime.split(":")[0];
        this.selectedMinutes =
          this.selectedAlarm.alarmNotificationTime.split(":")[1];
      } else if (type == "arrival" && this.selectedAlarm.siteArrivalTime) {
        this.selectedHour = this.selectedAlarm.siteArrivalTime.split(":")[0];
        this.selectedMinutes = this.selectedAlarm.siteArrivalTime.split(":")[1];
      } else {
        this.selectedHour = moment().format("HH");
        this.selectedMinutes = moment().format("mm");
      }

      this.selectedTextField = type;
      this.timePickerDialog = true;
    },
    setSelectedTime() {
      if (this.selectedTextField == "arrival") {
        this.selectedAlarm.siteArrivalTime =
          this.selectedHour + ":" + this.selectedMinutes;
      } else {
        this.selectedAlarm.alarmNotificationTime =
          this.selectedHour + ":" + this.selectedMinutes;
      }

      this.timePickerDialog = false;
      this.selectedTextField = null;
    },
    async sendEmail() {
      let string = this.selectedAlarm.emailSent
        ? "Email has already been sent, would you like to send again?"
        : "Are you sure you want to email this report?";

      if (confirm(string)) {
        this.isLoading = true;
        await this.updateAlarmResponse();
        setTimeout(() => {
          axios.post(
            "https://apigw.guardex.co.nz/reports/sendemail",
            {
              id: this.selectedAlarm.id,
              reportType: "ALARM",
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("apollo-token"),
              },
            }
          );
        }, 1);

        this.selectedAlarm.emailSent = true;

        this.isLoading = false;
      }
    },
    isMobile() {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 58) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async deleteAlarmResponse() {
      this.isLoading = true;
      setTimeout(async () => {
        let res = await this.$apollo.mutate({
          mutation: deleteAlarmResponse,
          variables: {
            id: this.selectedAlarm.id,
          },
        });
        this.alarmResponseList = await this.alarmResponseList.filter(
          (obj) => obj.id != res.data.deleteAlarmResponse["_id"]
        );
        this.alarmResponseList = await this.alarmResponseList.filter(
          (obj) => obj["_id"] != res.data.deleteAlarmResponse["_id"]
        );
        setTimeout(() => {
          this.$router.push("/Reports");
        }, 1000);
        (this.color = "red"),
          (this.notificationText = "Alarm Response Deleted"),
          (this.notify = true);
      }, 1000);
    },

    removePhoto(index) {
      let properIndex =
        this.attachmentsList.length - index - this.filesList.length;
      this.filesList.splice(properIndex, 1);
      this.attachmentsList.splice(index, 1);
      this.selectedAlarm.attachments.splice(index, 1);
    },
    addPhoto() {
      window.addEventListener("focus", () => {
        once: true;
      });
      this.$refs.uploader.click();
    },

    async onFileChanged(e) {
      let selectFile = e.target.files[0];
      this.filesList.push(selectFile);
      this.attachmentsList.push(URL.createObjectURL(selectFile));

      e.target.value = "";

      // this.attachmentsList.push(URL.createObjectURL(selectFile));
    },

    async uploadFiles() {
      for (var i = 0; i < this.filesList.length; i++) {
        var formData = new FormData();
        formData.append("file", this.filesList[i]);
        let res = await axios.post(
          "https://apigw.guardex.co.nz/attachments",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("apollo-token"),
            },
          }
        );
        if (res.data.responseCode == 200) {
          this.selectedAlarm.attachments.push(
            "https://apigw.guardex.co.nz/" + res.data.body.filepath
          );
        }
      }
      this.addAttachmentsDialog = false;
    },

    initAttachmentDialog() {
      if (
        this.selectedAlarm.attachments &&
        this.selectedAlarm.attachments.length > 0
      ) {
        this.attachmentsList = this.attachmentsList.concat(
          this.selectedAlarm.attachments.filter(
            (item) => this.attachmentsList.indexOf(item) < 0
          )
        );
      } else {
        this.selectedAlarm.attachments = [];
      }
      this.addAttachmentsDialog = true;
    },

    async addAlarmResponse() {
      this.isLoading = true;
      setTimeout(async () => {
        let valid = this.$refs.form.validate();
        await this.uploadFiles();
        if (valid) {
          delete this.selectedAlarm.monitoringCompanyCombo
            .monitoringCompanyDetails;
          delete this.selectedAlarm.monitoringCompanyCombo["__typename"];
          delete this.selectedAlarm.monitoringCompanyCombo.personSpokenTo[
            "__typename"
          ];

          try {
            await this.$apollo.mutate({
              mutation: addAlarmResponse,
              variables: {
                ...this.selectedAlarm,
                dateCreated: Date.now().toString(),
              },
              update: (store, { data: { addAlarmResponse } }) => {
                let newResponseReport = {
                  ...this.selectedAlarm,
                  id: addAlarmResponse["_id"],
                };
                this.alarmResponseList.push(newResponseReport);
              },
            });
          } catch (error) {
            console.warn(error);
            this.isLoading = false;
          }

          setTimeout(() => {
            this.$router.push("/Reports");
          }, 1000);
          (this.color = "green darken-1"),
            (this.notificationText = "Alarm Response Submitted"),
            (this.notify = true);
        } else {
          this.submitDialog = false;
          setTimeout(() => {
            (this.color = "red "),
              (this.notificationText =
                "Cannot save report - please check all necessary fields are filled out"),
              (this.notify = true);
            this.isLoading = false;
          }, 250);
        }
      }, 1000);
      this.isLoading = false;
    },

    async updateAlarmResponse() {
      this.isLoading = true;
      let valid = this.$refs.form.validate();
      await this.uploadFiles();
      if (valid) {
        delete this.selectedAlarm.monitoringCompanyCombo["__typename"];
        delete this.selectedAlarm.monitoringCompanyCombo.personSpokenTo[
          "__typename"
        ];
        delete this.selectedAlarm.monitoringCompanyCombo
          .monitoringCompanyDetails;

        try {
          await this.$apollo.mutate({
            mutation: updateAlarmResponse,
            variables: {
              ...this.selectedAlarm,
            },
            update: (store, { data: { updateAlarmResponse } }) => {
              let newResponseReport = {
                ...this.selectedAlarm,
                id: updateAlarmResponse["_id"],
              };
              const index = this.alarmResponseList.findIndex(
                (report) => report.id === newResponseReport.id
              );
              newResponseReport.monitoringCompanyCombo.monitoringCompanyDetails =
                updateAlarmResponse.monitoringCompanyCombo.monitoringCompanyDetails;
              this.alarmResponseList[index] = newResponseReport;
            },
          });
        } catch (error) {
          console.warn(error);
          this.isloading = false;
        }

        setTimeout(() => {
          this.$router.push("/Reports");
        }, 1000);
        (this.color = "green darken-1"),
          (this.notificationText = "Alarm Response Updated"),
          (this.notify = true);
      } else {
        this.submitDialog = false;
        setTimeout(() => {
          (this.color = "red "),
            (this.notificationText =
              "Cannot save report - please check all necessary fields are filled out"),
            (this.notify = true);
          this.isLoading = false;
        }, 250);
      }
      this.isLoading = false;
    },

    async updateAlarmClean() {
      this.isLoading = true;
      let valid = this.$refs.form.validate();
      await this.uploadFiles();
      if (valid) {
        delete this.selectedAlarm.monitoringCompanyCombo["__typename"];
        delete this.selectedAlarm.monitoringCompanyCombo.personSpokenTo[
          "__typename"
        ];
        delete this.selectedAlarm.monitoringCompanyCombo
          .monitoringCompanyDetails;

        try {
          await this.$apollo.mutate({
            mutation: updateAlarmClean,
            variables: {
              ...this.selectedAlarm,
            },
            update: (store, { data: { updateAlarmClean } }) => {
              let newResponseReport = {
                ...this.selectedAlarm,
                id: updateAlarmClean["_id"],
              };
              const index = this.alarmResponseList.findIndex(
                (report) => report.id === newResponseReport.id
              );
              newResponseReport.monitoringCompanyCombo.monitoringCompanyDetails =
                updateAlarmClean.monitoringCompanyCombo.monitoringCompanyDetails;
              this.alarmResponseList[index] = newResponseReport;
            },
          });
        } catch (error) {
          console.warn(error);
          this.isloading = false;
        }

        setTimeout(() => {
          this.$router.push("/Reports");
        }, 1000);
        (this.color = "green darken-1"),
          (this.notificationText = "Alarm Response Updated"),
          (this.notify = true);
      } else {
        this.submitDialog = false;
        setTimeout(() => {
          (this.color = "red "),
            (this.notificationText =
              "Cannot save report - please check all necessary fields are filled out"),
            (this.notify = true);
          this.isLoading = false;
        }, 250);
      }
      this.isLoading = false;
    },

    officerNeeded() {
      this.policeRequired = true;
      if (this.selectedAlarm.policeRequired == false) {
        this.$store.commit("alarmReports/resetRequiredPolice");
        this.policeRequired = false;
      } else
        this.selectedAlarm.policeInvolved.push({
          officerName: this.selectedAlarm.policeInvolved.officerName,
          officerStation: this.selectedAlarm.policeInvolved.officerStation,
          policeEventNumber:
            this.selectedAlarm.policeInvolved.policeEventNumber,
          notes: this.selectedAlarm.policeInvolved.notes,
        });
    },
    addOfficer() {
      this.selectedAlarm.policeInvolved.push({
        officerName: this.selectedAlarm.policeInvolved.officerName,
        officerStation: this.selectedAlarm.policeInvolved.officerStation,
        policeEventNumber: this.selectedAlarm.policeInvolved.policeEventNumber,
        notes: this.selectedAlarm.policeInvolved.notes,
      });
    },

    confirmRemoveOfficerDialog(index) {
      this.removeOfficerDialog = true;
      this.deleteIndex = index;
    },

    removeOfficer() {
      if (this.deleteIndex >= 0) {
        this.selectedAlarm.policeInvolved.splice(this.deleteIndex, 1);
      }
      this.removeOfficerDialog = false;
    },

    confirmRemoveInjuredDialog(index) {
      this.removeInjuredPersonDialog = true;
      this.deleteIndex = index;
    },

    removeInjuredPerson() {
      if (this.deleteIndex >= 0) {
        this.selectedAlarm.peopleInjured.splice(this.deleteIndex, 1);
      }
      this.removeInjuredPersonDialog = false;
    },

    injuredSwitch() {
      this.anyoneInjured = true;
      if (this.selectedAlarm.anyoneInjured == false) {
        this.$store.commit("alarmReports/resetInjuredPerson");
        this.anyoneInjured = false;
      } else
        this.selectedAlarm.peopleInjured.push({
          firstName: this.selectedAlarm.peopleInjured.firstName,
          lastName: this.selectedAlarm.peopleInjured.lastName,
          injuryDescription: this.selectedAlarm.peopleInjured.injuryDescription,
          notes: this.selectedAlarm.peopleInjured.notes,
        });
    },

    addInjuredPerson() {
      this.selectedAlarm.peopleInjured.push({
        firstName: this.selectedAlarm.peopleInjured.firstName,
        lastName: this.selectedAlarm.peopleInjured.lastName,
        injuryDescription: this.selectedAlarm.peopleInjured.injuryDescription,
        notes: this.selectedAlarm.peopleInjured.notes,
      });
    },

    contactMonitorCompany() {
      this.hasMonitoringCompanyContacted = true;
      if (this.selectedAlarm.hasMonitoringCompanyContacted == false) {
        this.$store.commit("alarmReports/resetMonitoringCompany");
        this.hasMonitoringCompanyContacted = false;
      }
    },

    addProperty() {
      this.selectedAlarm.damagedProperties.push({
        propertyName: this.selectedAlarm.damagedProperties.propertyName,
        propertyAddress: this.selectedAlarm.damagedProperties.propertyAddress,
      });
    },

    confirmRemoveProperty(index) {
      this.removePropertyDialog = true;
      this.deleteIndex = index;
    },

    removeProperty() {
      if (this.deleteIndex >= 0) {
        this.selectedAlarm.damagedProperties.splice(this.deleteIndex, 1);
      }
      this.removePropertyDialog = false;
    },

    alarmReactivated() {
      this.hasAlarmReactivated = true;
      if (this.selectedAlarm.hasAlarmReactivated == false) {
        this.hasAlarmReactivated = false;
      }
    },

    back() {
      this.$router.push("/Reports");
      this.$store.commit("alarmReports/resetSelectedAlarm");
    },
  },

  component: {
    //
  },

  apollo: {
    alarmResponseList: alarmResponseList,
    enabledSites: enabledSites,
    enabledGuards: enabledGuards,
    clientList: clients,
    enabledMonitoringCompanyList: enabledMonitoringCompanyList,
  },

  async mounted() {
    if (!this.selectedAlarm) {
      this.$router.push("Reports");
    }
    if (!this.isAdmin) {
      this.selectedAlarm.currentGeoLocation =
        this.myGeolocation[0].location.lat +
        "," +
        this.myGeolocation[0].location.lng;
      let guard = this.$store.getters["guardDetails"];
      this.selectedAlarm.guardDetails = guard;
    }
  },

  computed: {
    hourListComputed() {
      let hourList = [];
      for (var i = 0; i < 24; i++) {
        hourList.push(("0" + i).slice(-2));
      }
      return hourList;
    },

    minutesListComputed() {
      let minutesList = [];
      for (var i = 0; i < 60; i++) {
        minutesList.push(("0" + i).slice(-2));
      }
      return minutesList;
    },
    displayDate() {
      return this.selectedAlarm.dateIssued
        ? moment(this.selectedAlarm.dateIssued).format("DD/MM/YYYY")
        : "";
    },
    userInfo: {
      get() {
        return this.$store.getters["userInfo"];
      },
    },
    myGeolocation: {
      get() {
        return this.$store.getters["markers"];
      },
    },
    isAdmin: {
      get() {
        return this.$store.getters["isAdmin"];
      },
    },
    selectedAlarm: {
      get() {
        return this.$store.getters["alarmReports/selectedAlarm"];
      },
    },
  },

  watch: {
    selectedAlarm: function (val) {
      if (!val) {
        this.$store.commit("alarmReports/resetSelectedAlarm");
        // this.$store.commit("alarmReports/resetRequiredPolice");
        // this.$store.commit("alarmReports/resetInjuredPerson");
        this.$store.commit("alarmReport/resetDamagedProperties");
      }
    },

    "selectedAlarm.siteLocation": function (val) {
      if (val) {
        this.selectedAlarm.siteLocationId = val["_id"];
      }
    },

    "selectedAlarm.guardDetails": function (val) {
      if (val) {
        this.selectedAlarm.guardOnDuty = val["_id"];
      }
    },

    "selectedAlarm.monitoringCompanyCombo.monitoringCompanyDetails": function (
      val
    ) {
      if (val) {
        this.selectedAlarm.monitoringCompanyCombo.monitoringCompanyId = val.id;
      }
    },
  },

  data: () => ({
    selectedTextField: null,
    selectedHour: null,
    selectedMinutes: null,
    timePickerDialog: false,
    alarmResponseList: [],
    enabledGuards: [],
    enabledSites: [],
    filesList: [],
    attachmentsList: [],
    enabledMonitoringCompanyList: [],
    removeOfficerDialog: false,
    deleteIndex: null,
    removeInjuredPersonDialog: false,
    submitDialog: false,
    confirmDeleteDialog: false,
    hasMonitoringCompanyContacted: false,
    policeRequired: false,
    noProblem: false,
    removePropertyDialog: false,
    dateIssuedDialog: false,
    max: 5,
    addAttachmentsDialog: false,
    isLoading: false,
    color: "",
    notificationText: "",
    notify: false,

    // rules
    policeDetailsRule: [(v) => !!v || "Please fill in the required field"],
    injuredPersonDetailRules: [
      (v) => !!v || "Please fill in the required field",
    ],
    rules: [(v) => !!v || "Please fill in the required field"],
  }),
};
</script>

<style scoped>
/*  */
</style>
