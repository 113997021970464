<template>
  <v-container v-if="selectedIncidentReport">
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col class="mt-4" cols="6" lg="6" md="6" sm="6" xs="6">
            <v-btn @click="back()" color="primary">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <!-- add attachments -->
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  @click="initAttachmentDialog()"
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  class="ml-2"
                >
                  <v-icon> mdi-paperclip </v-icon>
                </v-btn>
              </template>
              <span> Attachments </span>
            </v-tooltip>

            <!-- attachments dialog -->
            <v-dialog
              v-if="selectedIncidentReport"
              v-model="addAttachmentsDialog"
              max-width="1200"
              style="overflow: auto"
            >
              <v-card class="overflow-y-auto" height="85vh">
                <v-card-title>
                  Incident Report Attachments
                  <v-spacer></v-spacer>
                  <v-card-actions
                    class="d-flex justify-space-between mb-6"
                    :style="isMobile() ? `width: 100%` : ``"
                  >
                    <v-btn
                      @click="addAttachmentsDialog = false"
                      color="secondary"
                      icon
                      x-large
                    >
                      <v-icon> mdi-close </v-icon>
                    </v-btn>
                    <v-btn
                      @click="addAttachmentsDialog = false"
                      color="primary"
                      icon
                      x-large
                    >
                      <v-icon> mdi-check </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card-title>
                <!-- add img button -->
                <v-card-actions>
                  <v-row justify="center">
                    <v-btn @click="addPhoto" color="secondary" elevation="4">
                      Add Image
                      <input
                        class="d-none"
                        ref="uploader"
                        type="file"
                        accept="image/*"
                        @change="onFileChanged"
                      />
                    </v-btn>
                  </v-row>
                </v-card-actions>

                <!-- display images -->
                <v-container>
                  <v-row class="pa-4">
                    <v-col
                      v-for="(items, index) in attachmentsList"
                      :key="index"
                      cols="12"
                      lg="4"
                      md="4"
                      sm="6"
                      xs="12"
                    >
                      <v-card
                        class="mb-4 text-right"
                        style="position: relative"
                        elevation="4"
                        outlined
                        min-width="125"
                        height="280"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              style="
                                z-index: 1000;
                                position: absolute;
                                margin-left: -60px;
                                margin-top: 5px;
                              "
                              v-bind="attrs"
                              v-on="on"
                              @click="removePhoto(index)"
                              small
                              color="rgb(0, 0, 0, 0.2)"
                            >
                              <v-icon color="white">mdi-delete-outline</v-icon>
                            </v-btn>
                          </template>
                          <span> Remove Attachment </span>
                        </v-tooltip>
                        <a :href="items" target="_blank">
                          <v-img :src="items" height="100%" />
                        </a>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col class="text-right mt-4" cols="6" lg="6" md="6" sm="6" xs="6">
            <v-dialog v-model="confirmDeleteDialog" persistent max-width="320">
              <template #activator="{ on, attrs }">
                <v-icon
                  size="30"
                  :color="selectedIncidentReport.emailSent ? `green` : `gray`"
                  v-if="isAdmin && selectedIncidentReport.id"
                  class="mr-2 mt-2"
                  @click="sendEmail()"
                  >{{
                    selectedIncidentReport.emailSent
                      ? `mdi-email-check`
                      : `mdi-email-alert`
                  }}</v-icon
                >

                <v-btn
                  v-if="selectedIncidentReport.id !== null"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  color="error"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn
                  v-if="isAdmin && selectedIncidentReport.id"
                  class="white--text mr-2"
                  color="primary"
                  @click="printPDF()"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <v-card color="red">
                <v-card-title class="text-h5">
                  <v-icon class="mr-2" color="yellow" large> mdi-alert </v-icon>
                  Delete Report
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-text class="black--text mt-2">
                  <b
                    >This action cannot be undone, are you sure you want to
                    delete this report?</b
                  >
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    text
                    @click="confirmDeleteDialog = false"
                    :disabled="isLoading"
                  >
                    <b> Cancel </b>
                  </v-btn>
                  <v-btn
                    @click="deleteIncidentResponse()"
                    class="white--text"
                    color="yellow darken-1"
                    text
                    :loading="isLoading"
                  >
                    <b> Confirm Delete </b>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- submit dialog -->
            <v-dialog v-model="submitDialog" persistent max-width="320">
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  class="white--text"
                >
                  <!-- {{
                    selectedIncidentReport.id == null
                      ? `Submit`
                      : `Save Changes`
                  }} -->
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
                <v-btn
                  v-if="!selectedIncidentReport.id"
                  @click="goodReport()"
                  color="primary"
                  class="mr-2"
                >
                  NTR
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  <v-icon class="mr-2" color="info" large>
                    mdi-information
                  </v-icon>
                  {{
                    selectedIncidentReport.id == null
                      ? `Confirm Submission`
                      : `Confirm Changes`
                  }}
                </v-card-title>
                <v-spacer></v-spacer>
                <v-card-text class="mt-2">
                  <!-- Verify that all of the information you entered is accurate and correct prior to submitting -->
                  Check all site details are correct prior to submitting
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    :disabled="isLoading"
                    text
                    @click="submitDialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :loading="isLoading"
                    @click="
                      () => {
                        selectedIncidentReport.id == null
                          ? addIncidentResponse()
                          : updateIncidentResponse();
                      }
                    "
                    class="white--text"
                    color="green darken-1"
                    text
                  >
                    {{
                      selectedIncidentReport.id == null
                        ? `Confirm Submission`
                        : `Save Changes`
                    }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>

      <!-- autofill -->
      <!-- <v-container
        class="pa-0"
        fluid
      >
        <v-row>
          <v-col md="4">
            <v-switch
              @change="goodReport()"
              class="switch-border, ml-2"
              label="AutoFill as NTR"
              inset
            />
          </v-col>
        </v-row>
      </v-container> -->

      <!-- Incident Details -->
      <v-container v-if="selectedIncidentReport">
        <span>Incident Details</span>
        <v-card class="mb-6 pa-4" elevation="4">
          <div>
            <v-row>
              <v-col class="mt-4" cols="12" lg="6" md="6" sm="6" xs="12">
                <!-- date issued calendar -->
                <v-autocomplete
                  v-model="selectedIncidentReport.siteLocation"
                  item-value="siteLocation"
                  :items="enabledSites"
                  item-text="siteName"
                  label="Site Location"
                  hide-no-data
                  :rules="rules"
                  cache-items
                  outlined
                  return-object
                />
                <v-select
                  v-model="selectedIncidentReport.guardDetails"
                  :item-text="(item) => item.firstName + ` ` + item.lastName"
                  :items="enabledGuards"
                  label="Guard On Duty"
                  outlined
                  :rules="rules"
                  return-object
                  :readonly="!isAdmin"
                />

                <!-- incident notification time -->
              </v-col>

              <v-col class="mt-4" cols="12" lg="6" md="6" sm="6" xs="12">
                <v-dialog
                  ref="dateIssuedDialog"
                  v-model="dateIssuedDialog"
                  :return-value.sync="selectedIncidentReport.dateIssued"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="displayDate"
                      :value="convertDate(selectedIncidentReport.dateIssued)"
                      label="Incident Date"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      :rules="rules"
                    />
                  </template>
                  <v-date-picker
                    v-model="selectedIncidentReport.dateIssued"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dateIssuedDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="
                        $refs.dateIssuedDialog.save(
                          selectedIncidentReport.dateIssued
                        )
                      "
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

                <v-text-field
                  v-model="selectedIncidentReport.incidentNotificationTime"
                  label="Time of Incident"
                  outlined
                  append-icon="mdi-clock-time-three-outline"
                  @keypress="isNumber($event)"
                  :maxlength="max"
                  :rules="rules"
                  readonly
                  @click="initTimePicker('incident')"
                />
                <!-- <v-text-field
                  v-model="selectedIncidentReport.currentGeoLocation"
                  label="Current Geolocation"
                  v-if="!isAdmin"
                  outlined
                /> -->

                <!-- site arrival time -->
                <!-- <v-text-field
                  v-model="selectedIncidentReport.siteArrivalTime"
                  label="Site Arrival Time"
                  append-icon="mdi-clock-time-four-outline"
                  outlined
                  :maxlength="max"
                  @keypress="isNumber($event)"
                /> -->

                <!-- Guard On Duty -->
              </v-col>
            </v-row>
            <!-- site location -->

            <!-- incident description -->
            <v-textarea
              v-model="selectedIncidentReport.incidentDescription"
              label="Incident Description"
              outlined
              no-resize
              height="144"
              :rules="rules"
            />
          </div>
        </v-card>

        <!-- Damaged properties -->
        <v-card class="mb-6 pa-4" elevation="4">
          <p>Damaged Properties</p>

          <div
            v-for="(
              propertyDetails, i
            ) in selectedIncidentReport.damagedProperty"
            :key="i"
          >
            <v-col class="mt-4" cols="12" lg="12" md="12" sm="6" xs="12">
              <!-- damage property -->
              <v-textarea
                v-model="propertyDetails.propertyDamageDetails"
                label="Property Damage Description"
                outlined
                no-resize
                height="144"
                :rules="rules"
              />
            </v-col>
            <v-row class="pa-2 pb-4 d-flex flex-row-reverse">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="confirmRemoveProperty(i)"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    small
                  >
                    <v-icon color="red"> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span> Remove Property</span>
              </v-tooltip>
            </v-row>
          </div>

          <!-- delete property dialog -->
          <v-dialog
            color="red"
            v-model="removePropertyDialog"
            persistent
            max-width="300"
          >
            <v-card>
              <v-card-title class="text-h5"> Remove Property </v-card-title>
              <v-spacer></v-spacer>
              <v-card-text class="black--text mt-2">
                Are you sure you want to remove this property?
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn text @click="removePropertyDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  @click="removeProperty()"
                  class="white--text"
                  color="red darken-1"
                  text
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card-actions>
            <v-row justify="center">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="addProperty()"
                    class="white--text"
                    color="#EA690B"
                    v-on="on"
                    v-bind="attrs"
                    small
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span> Add More </span>
              </v-tooltip>
            </v-row>
          </v-card-actions>
        </v-card>

        <!-- Police Required -->
        <span
          >Police Required
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col md="2">
                <v-switch
                  v-model="selectedIncidentReport.policeRequired"
                  @change="officerNeeded()"
                  :label="`
             ${selectedIncidentReport.policeRequired ? `Yes` : `No`} 
            `"
                  color="#EA690B"
                  inset
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-container>
        </span>
        <v-card
          v-if="selectedIncidentReport.policeRequired"
          class="mb-6 pa-4"
          elevation="4"
        >
          <v-row>
            <v-col class="mt-4" cols="12" lg="12" md="12" sm="6" xs="12">
              <div
                v-for="(
                  officerDetails, i
                ) in selectedIncidentReport.policeInvolved"
                :key="i"
              >
                <p>Officer Involved</p>
                <v-text-field
                  v-model="officerDetails.officerName"
                  label="Officer Name"
                  :rules="policeDetailsRule"
                  outlined
                />
                <v-text-field
                  v-model="officerDetails.officerStation"
                  label="Officer Station"
                  outlined
                />
                <v-text-field
                  v-model="officerDetails.policeEventNumber"
                  label="Police Event Number"
                  :rules="policeDetailsRule"
                  outlined
                />
                <v-textarea
                  v-model="officerDetails.notes"
                  label="Notes"
                  height="144"
                  no-resize
                  outlined
                />

                <v-row class="pa-2 pb-4 d-flex flex-row-reverse">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        @click="confirmRemoveOfficerDialog(i)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        small
                      >
                        <v-icon color="red"> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span> Remove {{ officerDetails.officerName }} </span>
                  </v-tooltip>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <!-- delete officer dialog -->
          <v-dialog
            color="red"
            v-model="removeOfficerDialog"
            persistent
            max-width="300"
          >
            <v-card>
              <v-card-title class="text-h5"> Remove Officer </v-card-title>
              <v-spacer></v-spacer>
              <v-card-text class="mt-2">
                Are you sure you want to remove this officer?
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn text @click="removeOfficerDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  @click="removeOfficer()"
                  class="white--text"
                  color="red darken-1"
                  text
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- add officer -->
          <v-card-actions>
            <v-row justify="center">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="addOfficer()"
                    class="white--text"
                    color="#EA690B"
                    v-on="on"
                    v-bind="attrs"
                    small
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span> Add More </span>
              </v-tooltip>
            </v-row>
          </v-card-actions>
        </v-card>

        <!-- Anyone injured -->
        <span
          >Anyone injured
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col md="2">
                <v-switch
                  v-model="selectedIncidentReport.anyoneInjured"
                  color="#EA690B"
                  inset
                  @change="injuredSwitch()"
                  :label="`${
                    selectedIncidentReport.anyoneInjured ? `Yes` : `No`
                  }`"
                >
                </v-switch>
              </v-col>
            </v-row>
          </v-container>
        </span>
        <v-card
          v-if="selectedIncidentReport.anyoneInjured"
          class="mb-6 pa-4"
          elevation="4"
        >
          <v-row>
            <v-col class="mt-4" cols="12" lg="12" md="12" sm="6" xs="12">
              <div
                v-for="(
                  injuredPersonDetails, n
                ) in selectedIncidentReport.peopleInjured"
                :key="n"
              >
                <p>Injured Person</p>

                <v-text-field
                  v-model="injuredPersonDetails.firstName"
                  label="First Name"
                  :rules="injuredPersonDetailRules"
                  outlined
                />
                <v-text-field
                  v-model="injuredPersonDetails.lastName"
                  label="Last Name"
                  outlined
                />
                <v-textarea
                  v-model="injuredPersonDetails.injuryDescription"
                  label="Injury Description"
                  :rules="injuredPersonDetailRules"
                  outlined
                  no-resize
                  height="144"
                />
                <v-textarea
                  v-model="injuredPersonDetails.notes"
                  label="Extra Notes"
                  outlined
                  no-resize
                  height="144"
                />
                <v-row class="pa-2 pb-4 d-flex flex-row-reverse">
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        @click="confirmRemoveInjuredDialog(n)"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        small
                      >
                        <v-icon color="red"> mdi-delete </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Remove
                      {{
                        injuredPersonDetails.firstName +
                        " " +
                        injuredPersonDetails.lastName
                      }}
                    </span>
                  </v-tooltip>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <!-- delete injured person dialog -->
          <v-dialog
            color="red"
            v-model="removeInjuredPersonDialog"
            persistent
            max-width="300"
          >
            <v-card>
              <v-card-title class="text-h5">
                Remove Injured Person
              </v-card-title>
              <v-spacer></v-spacer>
              <v-card-text class="mt-2">
                Are you sure you want to remove this injured person?
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn text @click="removeInjuredPersonDialog = false">
                  Cancel
                </v-btn>
                <v-btn
                  @click="removeInjuredPerson()"
                  class="white--text"
                  color="red darken-1"
                  text
                >
                  Confirm
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-card-actions>
            <v-row justify="center">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    @click="addInjuredPerson()"
                    class="white--text"
                    color="#EA690B"
                    v-on="on"
                    v-bind="attrs"
                    small
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </template>
                <span> Add More </span>
              </v-tooltip>
            </v-row>
          </v-card-actions>
        </v-card>

        <!-- Additional Details -->
        <span>Additional Details </span>
        <v-row>
          <v-col class="mt-4" cols="12" lg="12" md="12" sm="12" xs="12">
            <v-textarea
              v-model="selectedIncidentReport.additionalDetails"
              outlined
              no-resize
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <!-- notification  -->
    <v-snackbar
      v-model="notify"
      class="pt-16"
      top
      right
      :timeout="2500"
      :color="color"
    >
      {{ notificationText }}
    </v-snackbar>

    <!----------- TIME PICKER ------------->
    <v-dialog v-model="timePickerDialog" max-width="290" persistent>
      <v-card min-height="24vh">
        <v-container>
          <v-row>
            <v-col cols="6" lg="6" md="6" sm="6">
              <v-btn @click="timePickerDialog = false" icon>
                <v-icon large> mdi-chevron-left </v-icon>
              </v-btn>
              Time
            </v-col>
            <v-col cols="6" lg="6" md="6" sm="6">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div class="d-flex justify-end">
                    <v-btn
                      @click="setSelectedTime()"
                      class=""
                      v-on="on"
                      v-bind="attrs"
                      icon
                    >
                      <v-icon color="secondary"> mdi-check-circle </v-icon>
                    </v-btn>
                  </div>
                </template>
                Save
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>

        <v-container>
          <v-row class="pt-8" justify="center">
            <v-col cols="4" lg="4" md="4" sm="4">
              <v-autocomplete
                v-model="selectedHour"
                :items="hourListComputed"
                append-icon=""
                outlined
              />
            </v-col>
            <p style="font-size: 51px">:</p>
            <v-col cols="4" lg="4" md="4" sm="4">
              <v-autocomplete
                v-model="selectedMinutes"
                :items="minutesListComputed"
                append-icon=""
                outlined
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  incidentResponseList,
  addIncidentResponse,
  deleteIncidentResponse,
  updateIncidentResponse,
  updateIncidentClean,
} from "../graphql/incidentReports";
import { sites, enabledSites } from "../graphql/sites";
import { enabledGuards } from "../graphql/guard";
import Sites from "../views/Sites.vue";
import { clients } from "../graphql/clients";
import moment from "moment";
import axios from "axios";

export default {
  methods: {
    async printPDF() {
      await this.updateIncidentClean();
      let result = await axios.post(
        "https://apigw.guardex.co.nz/reports/print",
        "incident#" + this.selectedIncidentReport["id"],
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("apollo-token"),
          },
        }
      );

      const blob = await this.b64toBlob(result.data, "application/pdf");
      const blobUrl = URL.createObjectURL(blob);

      window.open(blobUrl);
    },

    async b64toBlob(b64, contentType, sliceSize = 522) {
      const byteCharacters = window.atob(b64);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });

      console.log(blob);
      return blob;
    },
    convertDate(d) {
      return d && d.length > 0 ? moment(parseInt(d)).format("DD/MM/yyyy") : "";
    },
    initTimePicker(type) {
      if (
        type == "incident" &&
        this.selectedIncidentReport.incidentNotificationTime
      ) {
        this.selectedHour =
          this.selectedIncidentReport.incidentNotificationTime.split(":")[0];
        this.selectedMinutes =
          this.selectedIncidentReport.incidentNotificationTime.split(":")[1];
      } else {
        this.selectedHour = moment().format("HH");
        this.selectedMinutes = moment().format("mm");
      }

      this.selectedTextField = type;
      this.timePickerDialog = true;
    },
    setSelectedTime() {
      if (this.selectedTextField == "incident") {
        this.selectedIncidentReport.incidentNotificationTime =
          this.selectedHour + ":" + this.selectedMinutes;
      }

      this.timePickerDialog = false;
      this.selectedTextField = null;
    },
    addProperty() {
      this.selectedIncidentReport.damagedProperty.push({
        propertyDamageDetails:
          this.selectedIncidentReport.damagedProperty.propertyDamageDetails,
      });
    },

    // addProperty() {
    //   this.selectedAlarm.damagedProperties.push({
    //     propertyName: this.selectedAlarm.damagedProperties.propertyName,
    //     propertyAddress: this.selectedAlarm.damagedProperties.propertyAddress,
    //   });
    // },

    removeProperty() {
      if (this.deleteIndex >= 0) {
        this.selectedIncidentReport.damagedProperty.splice(this.deleteIndex, 1);
      }
      this.removePropertyDialog = false;
    },

    confirmRemoveProperty(index) {
      this.removePropertyDialog = true;
      this.deleteIndex = index;
    },

    async sendEmail() {
      let string = this.selectedIncidentReport.emailSent
        ? "Email has already been sent. Are you sure to send again?"
        : "Are you sure you want to send report email?";

      if (confirm(string)) {
        this.isLoading = true;
        await this.updateIncidentResponse();

        await axios.post(
          "https://apigw.guardex.co.nz/reports/sendemail",
          {
            id: this.selectedIncidentReport.id,
            reportType: "INCIDENT",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("apollo-token"),
            },
          }
        );

        this.selectedIncidentReport.emailSent = true;

        this.isLoading = false;
      }
    },
    removePhoto(index) {
      let properIndex =
        this.attachmentsList.length - index - this.filesList.length;
      this.filesList.splice(properIndex, 1);

      this.attachmentsList.splice(index, 1);
      this.selectedIncidentReport.attachments.splice(index, 1);
    },

    isMobile() {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },

    addPhoto() {
      window.addEventListener("focus", () => {
        once: true;
      });
      this.$refs.uploader.click();
    },

    async onFileChanged(e) {
      let selectFile = e.target.files[0];
      this.filesList.push(selectFile);
      this.attachmentsList.push(URL.createObjectURL(selectFile));
      e.target.value = "";
    },

    async uploadFiles() {
      for (var i = 0; i < this.filesList.length; i++) {
        var formData = new FormData();
        formData.append("file", this.filesList[i]);

        let res = await axios.post(
          "https://apigw.guardex.co.nz/attachments",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("apollo-token"),
            },
          }
        );

        if (res.data.responseCode == 200) {
          this.selectedIncidentReport.attachments.push(
            "https://apigw.guardex.co.nz/" + res.data.body.filepath
          );
        }
      }
    },

    initAttachmentDialog() {
      if (
        this.selectedIncidentReport.attachments &&
        this.selectedIncidentReport.attachments.length > 0
      ) {
        this.attachmentsList = this.attachmentsList.concat(
          this.selectedIncidentReport.attachments.filter(
            (item) => this.attachmentsList.indexOf(item) < 0
          )
        );
      } else {
        this.selectedIncidentReport.attachments = [];
      }
      this.addAttachmentsDialog = true;
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 58) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    convertDate(d) {
      return d && d.length > 0 ? moment(d).format("DD/MM/yyyy") : "";
    },

    async deleteIncidentResponse() {
      this.isLoading = true;
      setTimeout(async () => {
        let res = await this.$apollo.mutate({
          mutation: deleteIncidentResponse,
          variables: {
            id: this.selectedIncidentReport.id,
          },
        });
        this.incidentResponseList = await this.incidentResponseList.filter(
          (obj) => obj.id != res.data.deleteIncidentResponse["_id"]
        );
        this.incidentResponseList = await this.incidentResponseList.filter(
          (obj) => obj["_id"] != res.data.deleteIncidentResponse["_id"]
        );
        setTimeout(() => {
          this.$router.push("/Reports");
        }, 1000);
        (this.color = "red"),
          (this.notificationText = "Incident Report Deleted"),
          (this.notify = true);
      }, 1000);
    },

    async addIncidentResponse() {
      this.isLoading = true;
      setTimeout(async () => {
        let valid = this.$refs.form.validate();
        if (this.selectedIncidentReport.id == null && valid) {
          try {
            await this.uploadFiles();
            await this.$apollo.mutate({
              mutation: addIncidentResponse,
              variables: {
                ...this.selectedIncidentReport,
                dateCreated: Date.now().toString(),
              },
              update: (store, { data: { addIncidentResponse } }) => {
                let newIncidentReport = {
                  ...this.selectedIncidentReport,
                  id: addIncidentResponse["_id"],
                };
                this.incidentResponseList.push(newIncidentReport);
              },
            });
          } catch (error) {
            console.warn(error);
            this.isLoading = true;
          }

          setTimeout(() => {
            this.$router.push("/Reports");
          }, 1000);
          (this.color = "green darken-1"),
            (this.notificationText = "Incident Report Submitted"),
            (this.notify = true);
        } else {
          this.submitDialog = false;
          setTimeout(() => {
            (this.color = "red "),
              (this.notificationText =
                "Cannot save report - please check all necessary fields are filled out"),
              (this.notify = true);
            this.isLoading = false;
          }, 250);
        }
      }, 1000);

      this.isLoading = false;
    },

    async updateIncidentResponse() {
      this.isLoading = true;
      let valid = this.$refs.form.validate();
      if (valid) {
        try {
          await this.uploadFiles();
          await this.$apollo.mutate({
            mutation: updateIncidentResponse,
            variables: {
              ...this.selectedIncidentReport,
            },
          });
        } catch (error) {
          console.warn(error);
          this.isLoading = true;
        }
        setTimeout(() => {
          this.$router.push("/Reports");
        }, 1000);
        (this.color = "green darken-1"),
          (this.notificationText = "Incident Report Updated"),
          (this.notify = true);
        this.isLoading - false;
      } else {
        this.submitDialog = false;
        this.isLoading = false;
        alert(
          "Cannot save report - please check all necessary fields are filled out"
        );
      }
      this.isLoading = true;
    },

    async updateIncidentClean() {
      this.isLoading = true;
      let valid = this.$refs.form.validate();
      if (valid) {
        await this.uploadFiles();
        await this.$apollo.mutate({
          mutation: updateIncidentClean,
          variables: {
            ...this.selectedIncidentReport,
          },
        });
        setTimeout(() => {
          this.$router.push("/Reports");
        }, 1000);
        (this.color = "green darken-1"),
          (this.notificationText = "Incident Report Updated"),
          (this.notify = true);
        this.isLoading - false;
      } else {
        this.submitDialog = false;
        this.isLoading = false;
        alert(
          "Cannot save report - please check all necessary fields are filled out"
        );
      }
    },

    officerNeeded() {
      this.policeRequired = true;
      if (this.selectedIncidentReport.policeRequired == false) {
        this.$store.commit("incidentReports/resetRequiredPolice");
        this.policeRequired = false;
      } else
        this.selectedIncidentReport.policeInvolved.push({
          officerName: this.selectedIncidentReport.policeInvolved.officerName,
          officerStation:
            this.selectedIncidentReport.policeInvolved.officerStation,
          policeEventNumber:
            this.selectedIncidentReport.policeInvolved.policeEventNumber,
          notes: this.selectedIncidentReport.policeInvolved.notes,
        });
    },

    addOfficer() {
      this.selectedIncidentReport.policeInvolved.push({
        officerName: this.selectedIncidentReport.policeInvolved.officerName,
        officerStation:
          this.selectedIncidentReport.policeInvolved.officerStation,
        policeEventNumber:
          this.selectedIncidentReport.policeInvolved.policeEventNumber,
        notes: this.selectedIncidentReport.policeInvolved.notes,
      });
    },

    confirmRemoveOfficerDialog(index) {
      this.removeOfficerDialog = true;
      this.deleteIndex = index;
    },

    removeOfficer() {
      if (this.deleteIndex >= 0) {
        this.selectedIncidentReport.policeInvolved.splice(this.deleteIndex, 1);
      }
      this.removeOfficerDialog = false;
    },

    confirmRemoveInjuredDialog(index) {
      this.removeInjuredPersonDialog = true;
      this.deleteIndex = index;
    },

    removeInjuredPerson() {
      if (this.deleteIndex >= 0) {
        this.selectedIncidentReport.peopleInjured.splice(this.deleteIndex, 1);
      }
      this.removeInjuredPersonDialog = false;
    },

    injuredSwitch() {
      this.anyoneInjured = true;
      if (this.selectedIncidentReport.anyoneInjured == false) {
        this.$store.commit("incidentReports/resetInjuredPerson");
        this.anyoneInjured = false;
      } else
        this.selectedIncidentReport.peopleInjured.push({
          firstName: this.selectedIncidentReport.peopleInjured.firstName,
          lastName: this.selectedIncidentReport.peopleInjured.lastName,
          injuryDescription:
            this.selectedIncidentReport.peopleInjured.injuryDescription,
          notes: this.selectedIncidentReport.peopleInjured.notes,
        });
    },

    addInjuredPerson() {
      this.selectedIncidentReport.peopleInjured.push({
        firstName: this.selectedIncidentReport.peopleInjured.firstName,
        lastName: this.selectedIncidentReport.peopleInjured.lastName,
        injuryDescription:
          this.selectedIncidentReport.peopleInjured.injuryDescription,
        notes: this.selectedIncidentReport.peopleInjured.notes,
      });
    },

    // autofill
    goodReport() {
      this.selectedIncidentReport.dateIssued = moment().format("YYYY-MM-DD");
      this.selectedIncidentReport.incidentNotificationTime =
        moment().format("HH:mm");
      // this.selectedIncidentReport.siteLocationId = "";
      this.selectedIncidentReport.currentGeoLocation = this.isAdmin
        ? null
        : this.myGeolocation[0].location.lat +
          "," +
          this.myGeolocation[0].location.lng;
      this.selectedIncidentReport.siteArrivalTime = moment().format("HH:mm");
      if (!this.isAdmin) {
        this.userInfo["http://guardex.com/custom"].app_metadata.guard_id;
      }
      // this.selectedIncidentReport.guardOnDuty = this.isAdmin ? undefined : "";
      this.selectedIncidentReport.incidentDescription = "NTR";
      this.selectedIncidentReport.policeRequired = false;
      this.selectedIncidentReport.policeInvolved = [];
      //  {
      //   officerName: "NTR",
      //   officerStation: "NTR",
      //   policeEventNumber: "NTR",
      //   notes: "NTR",
      // };
      this.selectedIncidentReport.anyoneInjured = false;
      this.selectedIncidentReport.peopleInjured = [];
      //  {
      //   firstName: "NTR",
      //   lastName: "NTR",
      //   injuryDescription: "NTR",
      //   notes: "NTR",
      // };
      this.selectedIncidentReport.additionalDetails = " NTR";
    },

    back() {
      this.$store.commit("incidentReports/resetSelectedIncidentReport");
      this.$router.push("/Reports");
    },
  },

  component: {
    Sites,
  },

  apollo: {
    incidentResponseList: incidentResponseList,
    enabledSites: enabledSites,
    enabledGuards: enabledGuards,
    clientList: clients,
  },

  async mounted() {
    if (this.selectedIncidentReport == null) {
      this.$router.push("Reports");
    }
    if (!this.isAdmin) {
      this.selectedIncidentReport.currentGeoLocation =
        this.myGeolocation[0].location.lat +
        "," +
        this.myGeolocation[0].location.lng;
      let guard = this.$store.getters["guardDetails"];
      this.selectedIncidentReport.guardDetails = guard;
      this.selectedIncidentReport.guardOnDuty = guard["_id"];
    }
    this.selectedSite = null;
  },

  computed: {
    hourListComputed() {
      let hourList = [];
      for (var i = 0; i < 24; i++) {
        hourList.push(("0" + i).slice(-2));
      }
      return hourList;
    },

    minutesListComputed() {
      let minutesList = [];
      for (var i = 0; i < 60; i++) {
        minutesList.push(("0" + i).slice(-2));
      }
      return minutesList;
    },
    displayDate() {
      return this.selectedIncidentReport.dateIssued
        ? moment(this.selectedIncidentReport.dateIssued).format("DD/MM/YYYY")
        : "";
    },
    userInfo: {
      get() {
        return this.$store.getters["userInfo"];
      },
    },
    myGeolocation: {
      get() {
        return this.$store.getters["markers"];
      },
    },
    isAdmin: {
      get() {
        return this.$store.getters["isAdmin"];
      },
    },
    selectedIncidentReport: {
      get() {
        return this.$store.getters["incidentReports/selectedIncidentReport"];
      },
    },
  },

  watch: {
    "selectedIncidentReport.siteLocation": function (val) {
      if (val) {
        this.selectedIncidentReport.siteLocationId = val["_id"];
      }
    },
    "selectedIncidentReport.guardDetails": function (val) {
      if (val) {
        this.selectedIncidentReport.guardOnDuty = val["_id"];
      }
    },
    selectedIncidentReport: function (val) {
      if (!val) {
        this.$store.commit("incidentReports/resetSelectedIncidentReport");
        this.$store.commit("incidentReports/resetRequiredPolice");
        this.$store.commit("incidentReports/resetInjuredPerson");
        this.$store.commit("incidentReports/resetDamagedProperty");
      }
    },
  },

  data: () => ({
    selectedTextField: null,
    selectedHour: null,
    selectedMinutes: null,
    timePickerDialog: false,
    notify: false,
    color: "",
    notificationText: "",
    hints: false,
    isAdd: false,
    policeRequired: false,
    anyoneInjured: false,
    removeOfficerDialog: false,
    deleteIndex: null,
    removeInjuredPersonDialog: false,
    submitDialog: false,
    confirmDeleteDialog: false,
    siteDialog: false,
    selectedSite: null,
    search: null,
    dateIssuedDialog: false,
    addAttachmentsDialog: false,
    deleteItem: null,
    isLoading: false,
    removePropertyDialog: false,

    // lists
    incidentResponseList: [],
    enabledGuards: [],
    enabledSites: [],
    clientList: [],
    clientManagerList: [],
    filesList: [],
    attachmentsList: [],

    // rules
    policeDetailsRule: [(v) => !!v || "Please fill in the required field"],
    injuredPersonDetailRules: [
      (v) => !!v || "Please fill in the required field",
    ],
    rules: [(v) => !!v || "Please fill in the required field"],

    max: 5,
  }),
};
</script>
