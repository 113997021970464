<template>
  <v-container v-if="isAdmin">
    <v-tabs v-model="tab" icons-and-text centered grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab @click="selectedTab(0)">
        Incident Reports <v-icon> mdi-alert </v-icon>
      </v-tab>
      <v-divider vertical inset> </v-divider>
      <v-tab @click="selectedTab(1)">
        Alarm Response <v-icon> mdi-bell-alert </v-icon>
      </v-tab>
      <v-divider vertical inset> </v-divider>
      <v-tab @click="selectedTab(2)">
        Checkpoint Entries <v-icon> mdi-map-marker-plus </v-icon>
      </v-tab>
      <v-divider vertical inset> </v-divider>
      <!-- <v-tab @click="selectedTab(3)">
				Checkpoints <v-icon> mdi-map-marker-multiple </v-icon>
			</v-tab> -->
    </v-tabs>
    <v-card>
      <v-tabs-items v-model="tab">
        <!-- incident reports -->
        <v-tab-item>
          <v-data-table
            v-if="selectedIncidentReport"
            dense
            :headers="incidentReportHeaders"
            :items="incidentResponseList"
            item-key="_id"
            class="elevation-1"
            single-expand
            mobile-breakpoint="0"
            :search="search"
            :items-per-page="25"
            :footer-props="{
              'items-per-page-options': [25, 40, 50, -1],
            }"
            sort-by="dateCreated"
            sort-desc
            :loading="$apollo.queries.incidentResponseList.loading"
          >
            <template v-slot:top>
              <v-row class="pa-4 d-flex">
                <v-col cols="12" lg="9" md="9" sm="12" xs="12">
                  <v-text-field
                    v-model="search"
                    label="Search Incident Reports"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col
                  v-show="!isMobile()"
                  cols="12"
                  lg="3"
                  md="3"
                  sm="12"
                  xs="12"
                >
                  <v-btn
                    @click="initAddIncidentReport()"
                    color="#EA690B"
                    class="white--text"
                    block
                  >
                    Add Incident Report
                  </v-btn>
                </v-col>
                <v-fab-transition>
                  <v-btn
                    v-show="isMobile()"
                    color="secondary"
                    dark
                    fixed
                    bottom
                    right
                    fab
                    class="mb-12"
                    @click="initAddIncidentReport()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-row>
            </template>

            <template v-slot:item="row">
              <tr
                @click="
                  () => {
                    row.expand(!row.isExpanded);
                  }
                "
              >
                <td>{{ convertDate(row.item.dateIssued) }}</td>
                <td>
                  {{
                    row.item.guardDetails
                      ? row.item.guardDetails.firstName +
                        " " +
                        row.item.guardDetails.lastName
                      : "Unknown Guard"
                  }}
                </td>
                <td>{{ row.item.siteLocation.siteName }}</td>
                <td>{{ row.item.policeRequired ? `Yes` : `No` }}</td>
                <td>
                  <v-icon @click="editReport(row.item)" small>
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </template>

            <!-- weird one  -->
            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-4" :colspan="headers.length">
                <tr v-if="item.incidentNotificationTime">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Notification Time : </span>
                    {{ item.incidentNotificationTime }}
                  </h4>
                </tr>
                <!-- <tr v-if="item.siteArrivalTime">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Site Arrival Time: </span>
                    {{ item.siteArrivalTime }}
                  </h4>
                </tr> -->

                <tr v-if="item.currentGeoLocation">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Current Geolocation: </span>
                    {{ item.currentGeoLocation }}
                  </h4>
                </tr>

                <tr v-if="item.anyoneInjured">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Anyone Injured?: </span>
                    {{ item.anyoneInjured == false ? `No` : `Yes` }}
                  </h4>
                </tr>

                <tr v-if="item.incidentDescription">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Incident Description: </span>
                    {{ item.incidentDescription }}
                  </h4>
                </tr>

                <tr v-if="item.additionalDetails">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Additional Details: </span>
                    {{ item.additionalDetails }}
                  </h4>
                </tr>
              </td>
            </template>
            <!-- Edit button -->
          </v-data-table>
        </v-tab-item>

        <!-- alarm response -->
        <v-tab-item>
          <v-data-table
            dense
            :headers="alarmResponseHeaders"
            :items="alarmResponseList"
            class="elevation-1"
            mobile-breakpoint="0"
            item-key="_id"
            single-expand
            :search="alarmSearch"
            :items-per-page="25"
            :footer-props="{
              'items-per-page-options': [25, 40, 50, -1],
            }"
            sort-by="dateCreated"
            sort-desc
            :loading="$apollo.queries.alarmResponseList.loading"
          >
            <template v-slot:top>
              <v-row class="pa-4 d-flex">
                <v-col cols="12" lg="9" md="9" sm="12" xs="12">
                  <v-text-field
                    v-model="alarmSearch"
                    label="Search Alarm Reports"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>

                <v-col
                  v-show="!isMobile()"
                  cols="12"
                  lg="3"
                  md="3"
                  sm="12"
                  xs="12"
                >
                  <v-btn
                    @click="initAddAlarmResponse()"
                    color="#EA690B"
                    class="white--text"
                    block
                  >
                    Add Alarm Response
                  </v-btn>
                </v-col>
                <v-fab-transition>
                  <v-btn
                    v-show="isMobile()"
                    color="secondary"
                    dark
                    fixed
                    bottom
                    right
                    fab
                    class="mb-12"
                    @click="initAddAlarmResponse()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-fab-transition>
              </v-row>
            </template>

            <template v-slot:item="row">
              <tr
                @click="
                  () => {
                    row.expand(!row.isExpanded);
                  }
                "
              >
                <td>{{ convertDate(row.item.dateIssued) }}</td>
                <td>
                  {{
                    row.item.guardDetails
                      ? row.item.guardDetails.firstName +
                        " " +
                        row.item.guardDetails.lastName
                      : "Unknown Guard"
                  }}
                </td>
                <td>{{ row.item.siteLocation.siteName }}</td>
                <td>{{ row.item.policeRequired ? `Yes` : `No` }}</td>
                <td>
                  <v-icon @click="editAlarmReport(row.item)" small>
                    mdi-pencil
                  </v-icon>
                </td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td class="pa-4" :colspan="headers.length">
                <tr v-if="item.incidentNotificationTime">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Notification Time : </span>
                    {{ item.incidentNotificationTime }}
                  </h4>
                </tr>
                <tr v-if="item.siteArrivalTime">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Site Arrival Time: </span>
                    {{ item.siteArrivalTime }}
                  </h4>
                </tr>

                <tr v-if="item.currentGeoLocation">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Current Geolocation: </span>
                    {{ item.currentGeoLocation }}
                  </h4>
                </tr>

                <tr v-if="item.anyoneInjured">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Anyone Injured?: </span>
                    {{ item.anyoneInjured == false ? `No` : `Yes` }}
                  </h4>
                </tr>

                <tr v-if="item.incidentDescription">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Incident Description: </span>
                    {{ item.incidentDescription }}
                  </h4>
                </tr>

                <tr v-if="item.additionalDetails">
                  <h4 style="font-size: 12px">
                    <span style="color: #888"> Additional Details: </span>
                    {{ item.additionalDetails }}
                  </h4>
                </tr>
              </td>
            </template>

            <!-- <template #[`item.dateIssued`]="{ item }">
              <td>{{ convertDate(item.dateIssued) }}</td>
            </template>

            <template #[`item.guardDetails`]="{ item }">
              <td>
                {{
                  item.guardDetails.firstName + " " + item.guardDetails.lastName
                }}
              </td>
            </template>

            <template #[`item.siteLocation`]="{ item }">
              <td>{{ item.siteLocation.siteName }}</td>
            </template>

            <template #[`item.policeRequired`]="{ item }">
              <td>
                {{ item.policeRequired ? `Yes` : `No` }}
              </td>
            </template>

            <template #[`item.edit`]="{ item }">
              <td>
                <v-icon
                  @click="editAlarmReport(item)"
                  small
                >
                  mdi-pencil
                </v-icon>
              </td>
            </template> -->
          </v-data-table>
        </v-tab-item>

        <!-- Checkpoint Entries Tab -->
        <v-tab-item>
          <v-data-table
            dense
            :headers="checkpointEntriesHeaders"
            :items="checkpointEntries"
            item-key="_id"
            class="elevation-1"
            mobile-breakpoint="0"
            :search="search"
            :items-per-page="25"
            :footer-props="{
              'items-per-page-options': [25, 40, 50, -1],
            }"
            sort-by="scanTime"
            sort-desc
            :loading="$apollo.queries.incidentResponseList.loading"
          >
            <template v-slot:top>
              <v-row class="pa-4 d-flex">
                <v-col cols="12" lg="3" md="3" sm="12" xs="12" class="d-flex">
                  <v-autocomplete
                    v-model="selectedSiteFilter"
                    item-value="siteLocation"
                    :items="enabledSites"
                    item-text="siteName"
                    label="Filter by Site Location"
                    hide-no-data
                    cache-items
                    outlined
                    return-object
                    dense
                    clearable
                  />
                </v-col>
                <v-col cols="12" lg="3" md="3" sm="12" xs="12" class="d-flex">
                  <v-autocomplete
                    v-model="selectedCheckpointFilter"
                    item-value="checkpoint"
                    :items="checkpointsList"
                    item-text="checkpoint"
                    label="Filter by Checkpoint"
                    hide-no-data
                    outlined
                    return-object
                    dense
                    clearable
                    :disabled="!selectedSiteFilter"
                  />
                </v-col>
                <v-col cols="12" lg="3" md="3" sm="12" xs="12" class="d-flex">
                  <v-autocomplete
                    v-model="selectedGuardFilter"
                    item-value="_id"
                    :items="guardsList"
                    :item-text="(item) => item.firstName + ' ' + item.lastName"
                    label="Filter by Guard"
                    hide-no-data
                    cache-items
                    outlined
                    dense
                    clearable
                    no-filter
                  />
                </v-col>
                <v-col cols="12" lg="3" md="3" sm="12" xs="12">
                  <v-dialog
                    ref="dateIssuedDialog"
                    v-model="dateIssuedDialog"
                    :return-value.sync="dateRange"
                    width="290px"
                    persistent
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="convertDateRange(dateRange)"
                        label="Filter Date Range"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        append-icon="mdi-calendar"
                        dense
                        clearable
                      />
                    </template>
                    <v-date-picker v-model="dateRange" range
                      ><v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="dateIssuedDialog = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dateIssuedDialog.save(dateRange)"
                      >
                        OK
                      </v-btn></v-date-picker
                    >
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  lg="12"
                  sm="12"
                  xs="12"
                  style="margin-top: -40px"
                  class="text-right"
                >
                  <v-btn
                    :block="isMobile()"
                    @click="printCheckpointEntries()"
                    color="#EA690B"
                    class="white--text"
                  >
                    <v-icon> mdi-printer </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item="row">
              <tr>
                <td>{{ convertScanDate(row.item.scanTime) }}</td>
                <td>
                  {{ row.item.guard }}
                </td>
                <td>{{ row.item.checkpoint }}</td>
                <td>{{ row.item.siteLocation }}</td>
                <td>
                  <a
                    target="_blank"
                    :href="`https://www.google.com/maps/place/${row.item.currentGeoLocation}`"
                    >{{ row.item.currentGeoLocation }}</a
                  >
                </td>
                <td class="text-right pr-10">
                  <v-icon
                    color="primary"
                    size="20"
                    @click="initDeleteCheckpointEntry(row.item)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- Checkpoint Entries Print Dialog -->
    <v-dialog v-model="checkpointEntriesPrintDialog" fullscreen>
      <v-card flat>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="checkpointEntriesPrintDialog = false"
            id="noPrint"
            icon
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-container id="toPrint" class="text-center px-12">
          <img
            class="guardexLogo"
            :src="require('../../public/img/Guardex-Logo.png')"
          />
          <v-spacer></v-spacer>
          <v-row no-gutters>
            <v-col cols="12">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        v-for="i in checkpointEntriesHeaders"
                        :key="i.value"
                        class="text-center"
                      >
                        <span :id="i.text == 'Actions' ? `noPrint` : ``">{{
                          i.text
                        }}</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="x in checkpointEntries" :key="x.id">
                      <td>{{ convertScanDate(x.scanTime) }}</td>
                      <td>{{ x.guard }}</td>
                      <td>{{ x.checkpoint }}</td>
                      <td>{{ x.siteLocation }}</td>
                      <td>
                        <a
                          target="_blank"
                          :href="`https://www.google.com/maps/place/${x.currentGeoLocation}`"
                          >{{ x.currentGeoLocation }}</a
                        >
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteCheckpointEntryDialog" max-width="400px">
      <v-card>
        <v-card-title class="text-h5"> Delete Checkpoint Entry</v-card-title>
        <v-card-text>Are you sure you want to delete this entry?</v-card-text>
        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            color="green darken-1"
            text
            @click="deleteCheckpointEntryDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            class="white--text"
            color="red darken-1"
            text
            @click="deleteCheckpointEntry()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-btn
      dark
      block
      height="40vh"
      color="#003366"
      class="mb-6"
      @click="initAddIncidentReport()"
    >
      <h2>Incident Response</h2>
    </v-btn>
    <v-btn @click="initAddAlarmResponse()" block height="40vh" color="#FF9900">
      <h2>Alarm Response</h2>
    </v-btn>
  </v-container>
</template>

<script>
import { incidentResponseList } from "../graphql/incidentReports";
import { alarmResponseList } from "../graphql/alarmReports";
import { enabledSites } from "../graphql/sites";
import { filterCheckpoints } from "../graphql/checkpoint";
import {
  listCheckpointEntries,
  filterCheckpointEntries,
  deleteCheckpointEntry,
} from "../graphql/checkpointEntry";
import { enabledGuards } from "../graphql/guard";
import moment from "moment";
import QRCode from "qrcode";

export default {
  methods: {
    async filterCheckpointList(site) {
      if (!site) {
        this.selectedCheckpointFilter = null;
        return;
      }
      let res = await this.$apollo.query({
        query: filterCheckpoints,
        variables: {
          siteId: site.id,
        },
      });

      this.checkpointsList = res.data.filterCheckpoints;

      this.$forceUpdate();
    },
    initDeleteCheckpointEntry(item) {
      this.selectedCheckpointEntry = item;
      this.deleteCheckpointEntryDialog = true;
    },

    async deleteCheckpointEntry() {
      let result = await this.$apollo.mutate({
        mutation: deleteCheckpointEntry,
        variables: {
          id: this.selectedCheckpointEntry["_id"],
        },
      });

      this.deleteCheckpointEntryDialog = false;
      this.selectedCheckpointEntry = null;

      const checkpointEntryIndex = this.checkpointEntries.findIndex(
        (entry) => entry.id === result.data.deleteCheckpointEntry.id
      );

      this.checkpointEntries.splice(checkpointEntryIndex, 1);
    },

    printCheckpointEntries() {
      this.checkpointEntriesPrintDialog = true;
      setTimeout(() => {
        print();
      }, 100);
      onafterprint = () => {
        //  close the dialog when printer dialog is closed
        // this.checkpointEntriesPrintDialog = false;
      };
    },
    async filterCheckpointEntries(selectedDateRange) {
      try {
        let checkpoint = this.selectedCheckpointFilter;
        let site = this.selectedSiteFilter;
        let guard = this.selectedGuardFilter;
        let date = !selectedDateRange ? null : selectedDateRange;

        let siteId = !site ? null : site.id;
        let guardId = !guard ? null : guard;
        let dateRange = !date
          ? null
          : {
              $gte: moment(date[0]).toISOString(),
              $lt: moment(date[1]).toISOString(),
            };
        let dateInputValue = JSON.stringify(dateRange);

        let input = {
          checkpoint: !checkpoint ? null : checkpoint.checkpoint,
          siteId: siteId,
          guardId: guardId,
          scanTime: dateInputValue == "null" ? null : dateInputValue,
        };

        const result = await this.$apollo.query({
          query: filterCheckpointEntries,
          variables: input,
        });
        this.checkpointEntries = result.data.entriesList;
      } catch (error) {
        console.warn(error);
      }
    },
    bytesToBase64(bytes) {
      const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join(
        ""
      );
      return btoa(binString);
    },
    convertScanDate(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY - H:mm");
      } else {
        return "";
      }
    },
    viewQrCode(url) {
      if (!url) return;
      this.qrCodeUrl = url;
      this.viewQrCodeDialog = true;
    },

    async printQrCode() {
      if (!this.isGenerate) return;
      this.printDialog = true;
      setTimeout(() => {
        print();
      }, 100);
      onafterprint = async () => {
        //  close the dialog when printer dialog is closed
        // this.printDialog = false;
      };
    },

    generateQrCode() {
      let isValid = this.$refs.qrForm.validate();
      if (!isValid) return;
      try {
        this.isLoading = true;
        this.isGenerate = true;
        let data = this.selectedCheckpoint;
        let encode = this.bytesToBase64(
          new TextEncoder().encode(JSON.stringify(data))
        );
        let qrData = [
          {
            data: encode,
            mode: "byte",
          },
        ];
        let qrUrl;
        QRCode.toDataURL(qrData, function (err, url) {
          qrUrl = url;
          if (err) {
            console.warn(err);
          }
        });
        this.qrCodeUrl = qrUrl;
        this.createCheckpoint();
      } catch (error) {
        console.warn(`Error: ${error}`);
      }
      this.isLoading = false;
    },
    initCheckpoint(checkpoint) {
      if (!checkpoint) {
        this.isAdd = true;
        this.$store.commit("checkpoint/resetSelectedCheckpoint");
      } else {
        this.isAdd = false;
        this.isGenerate = true;
        this.$store.commit("checkpoint/setSelectedCheckpoint", checkpoint);
      }
      this.checkpointDialog = true;
    },
    selectedTab(tab) {
      localStorage.setItem("reportTab", tab);
    },
    convertDate(d) {
      return d && d.length > 0 ? moment(d).format("DD/MM/yyyy") : "";
    },
    convertDateRange(d) {
      let newDateList = [];
      for (let i = 0; i < d.length; i++) {
        let date = d[i];
        newDateList.push(moment(date).format("DD/MM/YYYY"));
      }
      return newDateList.join(" to ");
    },
    isMobile() {
      if (this.$vuetify.breakpoint.width < 800) {
        return true;
      }
    },

    initAddIncidentReport() {
      if (!this.isAdmin) {
        if (!this.isClockedIn) {
          alert("Please clock-in to create reports");
          return;
        }
      }
      this.$store.commit("incidentReports/resetSelectedIncidentReport");
      this.$router.push("/CreateIncidentReport");
    },

    editReport(report) {
      this.$store.commit("incidentReports/setSelectedIncidentReport", report);
      this.$router.push("/CreateIncidentReport");
    },

    initAddAlarmResponse() {
      if (!this.isAdmin) {
        if (!this.isClockedIn) {
          alert("Please clock-in to create reports");
          return;
        }
      }
      this.$store.commit("alarmReports/resetSelectedAlarm");
      this.$router.push("/CreateAlarmReport");
    },

    editAlarmReport(alarmReport) {
      this.$store.commit("alarmReports/setSelectedAlarm", alarmReport);
      this.$router.push("/CreateAlarmReport");
    },
  },

  computed: {
    selectedCheckpoint: {
      get() {
        return this.$store.getters["checkpoint/selectedCheckpoint"];
      },
    },
    displayDate() {
      return this.selectedAlarm.dateIssued
        ? moment(this.selectedAlarm.dateIssued).format("DD/MM/YYYY")
        : "";
    },
    isClockedIn: {
      get() {
        return this.$store.getters["isClockedIn"];
      },
    },
    selectedIncidentReport: {
      get() {
        return this.$store.getters["incidentReports/selectedIncidentReport"];
      },
    },
    selectedAlarm: {
      get() {
        return this.$store.getters["alarmReports/selectedAlarm"];
      },
    },
    isAdmin: {
      get() {
        return this.$store.getters["isAdmin"];
      },
    },
  },

  apollo: {
    incidentResponseList: incidentResponseList,
    alarmResponseList: alarmResponseList,
    enabledSites: enabledSites,
    checkpointEntries: listCheckpointEntries,
    // checkpointsList: listCheckpoints,
    guardsList: enabledGuards,
  },

  components: {
    //
  },

  async mounted() {
    console.log(this.incidentResponseList);
    this.tab = parseInt(localStorage.getItem("reportTab"));

    // incident reports
    this.$store.commit("incidentReports/resetSelectedIncidentReport");
    this.$store.commit("incidentReports/resetRequiredPolice");
    this.$store.commit("incidentReports/resetInjuredPerson");

    // alarm reports
    this.$store.commit("alarmReports/resetSelectedAlarm");
    this.$store.commit("alarmReports/resetRequiredPolice");
    this.$store.commit("alarmReports/resetInjuredPerson");
    this.$store.commit("alarmReports/resetDamagedProperties");

    this.$store.commit("checkpoint/resetSelectedCheckpoint");
  },

  watch: {
    dateRange: function (date) {
      if (!date) return;
      this.filterCheckpointEntries(date);
    },
    selectedCheckpointFilter: function (checkpoint) {
      if (!checkpoint) this.selectedCheckpointFilter == null;
      this.filterCheckpointEntries();
    },
    selectedSiteFilter: function (site) {
      if (!site) this.selectedSiteFilter == null;
      this.filterCheckpointEntries();
      this.filterCheckpointList(site);
    },
    selectedGuardFilter: function (guard) {
      if (!guard) this.selectedGuardFilter == null;
      this.filterCheckpointEntries();
    },
    checkpointDialog: function (val) {
      if (val == false) {
        this.$store.commit("checkpoint/resetSelectedCheckpoint");
        this.isAdd = false;
        this.isGenerate = false;
      }
    },
    selectedIncidentReport: function (val) {
      if (!val) {
        this.$store.commit("incidentReports/resetSelectedIncidentReport");
        this.$store.commit("incidentReports/resetRequiredPolice");
        this.$store.commit("incidentReports/resetInjuredPerson");
      }
    },
    selectedAlarm: function (val) {
      if (!val) {
        this.$store.commit("alarmReports/resetSelectedAlarm");
        this.$store.commit("alarmReports/resetRequiredPolice");
        this.$store.commit("alarmReports/resetInjuredPerson");
        this.$store.commit("alarmReports/resetDamagedProperties");
      }
    },
  },

  data: () => ({
    selectedCheckpointEntry: null,
    deleteCheckpointEntryDialog: false,
    checkpointEntriesPrintDialog: false,
    selectedGuardFilter: null,
    guardsList: [],
    deleteConfirmationDialog: false,
    viewQrCodeDialog: "",
    checkpointsList: [],
    siteLocation: null,
    printDialog: false,
    requiredRule: [(v) => !!v || "Required Field"],
    qrCodeUrl: "",
    isGenerate: false,
    isLoading: false,
    isAdd: false,
    checkpointDialog: false,
    enabledSites: [],
    dateIssuedDialog: false,
    dateRange: [],
    selectedSiteFilter: null,
    selectedCheckpointFilter: null,
    checkpointEntries: [],
    checkpointHeaders: [
      {
        text: "QR",
        value: "qrCodeUrl",
      },
      {
        text: "Checkpoint",
        sortable: true,
        value: "checkpoint",
      },
      {
        text: "Site Location",
        sortable: true,
        value: "siteLocation",
      },
      { text: "View", sortable: false, value: "view" },
    ],
    checkpointEntriesHeaders: [
      {
        text: "Scan  Time",
        align: "start",
        sortable: true,
        value: "scanTime",
      },
      {
        text: "Reporting Guard",
        sortable: true,
        value: "guardDetails.firstName",
      },
      {
        text: "Checkpoint",
        sortable: true,
        value: "siteLocation.checkpoint",
      },
      {
        text: "Site Location",
        sortable: true,
        value: "siteLocation",
      },
      {
        text: "Geolocation",
        sortable: false,
        value: "currentGeoLocation",
      },
      {
        text: "Actions",
        value: "action",
        align: "right",
      },
    ],
    search: null,
    alarmSearch: null,
    // incident reports data
    incidentReportHeaders: [
      {
        text: "Date Created",
        align: "start",
        sortable: true,
        value: "dateCreated",
      },
      {
        text: "Reporting Guard",
        sortable: true,
        value: "guardDetails.firstName",
      },
      {
        text: "Site Location",
        sortable: true,
        value: "siteLocation.siteName",
      },
      { text: "Police Required", sortable: true, value: "policeRequired" },

      { text: "Edit", sortable: false, value: "edit" },
    ],
    incidentResponseList: [],

    // alarm response data
    alarmResponseHeaders: [
      {
        text: "Date Created",
        align: "start",
        sortable: true,
        value: "dateCreated",
      },
      {
        text: "Reporting Guard",
        sortable: true,
        value: "guardDetails.firstName",
      },
      {
        text: "Site Location",
        sortable: true,
        value: "siteLocation.siteName",
      },
      { text: "Police Required", sortable: true, value: "policeRequired" },
      { text: "Edit", sortable: false, value: "edit" },
    ],
    alarmResponseList: [],
    tab: null,
    expanded: [],
    isExpanded: false,
  }),
};
</script>

<style>
.guardexLogo {
  object-fit: contain;
  height: 300px;
  width: 300px;
  max-width: 100%;
  height: auto;
}

@media print {
  #noPrint {
    visibility: hidden;
  }
  #toPrint {
    visibility: visible;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
    /* Force print scaling using css */
    /* transform: scale(1.12); */
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>
